import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getAdjustmentsHistory = async (token, page, size, sortField, filtersData) => {

    const url = sortField ? `${BASE_API_URL}/stock-movements/adjustment-history?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/stock-movements/adjustment-history?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getAdjustmentsHistory call: ', error);
            return error;
        });
}

export const getAdjustmentMovements = async (token, page, size, sortField, filtersData, pathVariables) => {
    sortField = sortField ? sortField : 'createdDate,desc';
    const url = `${BASE_API_URL}/stock-movements/articleId/${pathVariables.articleId}/brand/${pathVariables.brand}/adjustments?page=${page}&size=${size}&sort=${sortField}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getAdjustmentMovements call: ', error);
            return error;
        });
}

export const getStockFinancialValue = async (token) => {
    const url = `${BASE_API_URL}/stock/value`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getStockFinancialValue call: ', error);
            return error;
        });
}

export const getStockFinancialByTags = async (token, page, size, sortField, filtersData) => {
    sortField = sortField ? sortField : 'tagValue,desc';
    const url = `${BASE_API_URL}/stock/tag-value?page=${page}&size=${size}&sort=${sortField}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getStockFinancialByTags call: ', error);
            return error;
        });
}

export const getStockFinancialValueFiltered = async (token, filtersData) => {
    const url = `${BASE_API_URL}/stock/value/filtered`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getStockFinancialValueFiltered call: ', error);
            return error;
        });
}

export const getMovementDistribution = async (token) => {
    const url = `${BASE_API_URL}/stock-movements/percentage`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getMovementDistribution call: ', error);
            return error;
        });
}

export const getStockReport = async (token, page, size, sortField, filtersData) => {

    const url = sortField ? `${BASE_API_URL}/stock/search?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/stock/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getStockReport call: ', error);
            return error;
        });
}

export const getMinimumReport = async (token, page, size, sortField, filtersData) => {

    const url = sortField ? `${BASE_API_URL}/stock/minimum?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/stock/minimum?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getMinimumReport call: ', error);
            return error;
        });
}

export const getMinimumPercentage = async (token) => {

    const url = `${BASE_API_URL}/stock/minimum/percentage`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getMinimumReport call: ', error);
            return error;
        });
}
