import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { styled } from "styled-components"
import { useForm } from "react-hook-form"

import { BiShow } from "react-icons/bi";
import { BiHide } from "react-icons/bi";

import { useAppContext } from "../contextApi/context";
import { changePassword } from "../services/AuthService"
import {
    CHANGED_PASSWORD_USER_NOT_FOUND,
    CHANGED_PASSWORD_WRONG_USER,
    CHANGED_PASSWORD_WRONG_TOKEN,
    CHANGED_PASSWORD_PASSWORD_MISMATCH
} from "../constants/apiMessages"
import logo from "../assets/img/logo-192x192.png"
import { NoAuthFormContainer } from "../styles/StyledComponents"


const ChangePassword = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm();

    // ----- State
    const [showError, setShowError] = useState(false);
    const [showErrorPasswordMismatch, setShowErrorPasswordMismatch] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // ----- Actions
    const goToDashboard = () => navigate("/dashboard")

    const handleChangePassword = async (data) => {
        clearMessages();

        const { token, username, password, confirmPassword } = data;

        const response = await changePassword(username, token, password, confirmPassword);

        if (response.status === 200) {
            navigate("/login");
        } else {
            // Error from API
            const { data } = response;

            if (data === CHANGED_PASSWORD_USER_NOT_FOUND || data === CHANGED_PASSWORD_WRONG_USER || data === CHANGED_PASSWORD_WRONG_TOKEN) {
                setShowError(true);
            }

            if (data === CHANGED_PASSWORD_PASSWORD_MISMATCH) {
                setShowErrorPasswordMismatch(true);
            }
        }
    }

    const togglePasswordShow = () => {
        setShowPassword(!showPassword)
    }

    const clearMessages = () => {
        setShowError(false);
        setShowErrorPasswordMismatch(false);
    }

    // ----- Render
    return (
        <>
            {loggedUser ? goToDashboard() :
                <MainContainer className="container">
                    <Logo src={logo} />
                    <NoAuthFormContainer className="shadow card p-4">
                        {showError &&
                            <div className="alert alert-danger text-center mb-1" role="alert">
                                Datos incorrectos.
                            </div>
                        }

                        {showErrorPasswordMismatch &&
                            <div className="alert alert-danger text-center mb-1" role="alert">
                                Las contraseñas no coinciden.
                            </div>
                        }

                        <h2 className="mb-3 text-center">Cambiar contraseña</h2>
                        <p className="text-muted text-center mb-3">Ingrese el token recibido por correo electrónico, su nombre de usuario y la nueva contraseña.</p>

                        <form onSubmit={handleSubmit(handleChangePassword)}>
                            <label className="d-flex mb-1" htmlFor="token">Token</label>
                            <input className="form-control" type="text" id="token" autoComplete="off" {...register("token", { required: true })} autoFocus />
                            <span className="my-1" role="alert">
                                {errors.token?.type === "required" && `Campo requerido`}
                            </span>

                            <label className="d-flex mb-1" htmlFor="username">Nombre de usuario</label>
                            <input className="form-control" type="text" id="username" {...register("username", { required: true, maxLength: 50 })} maxLength={50} autoComplete="off" />
                            <span className="my-1" role="alert">
                                {errors.username?.type === "required" && `Campo requerido`}
                                {errors.username?.type === "maxLength" && `Campo inválido`}
                            </span>

                            <label className="d-flex mb-1" htmlFor="newPassword">Contraseña nueva</label>
                            <div className="d-flex align-items-center position-relative">
                                <input className="form-control" type={showPassword ? "text" : "password"} id="newPassword" {...register("password", { required: true, maxLength: 50 })} maxLength={50} autoComplete="new-password" />
                                {showPassword ? <BiHide className="ms-2" onClick={togglePasswordShow}/> : <BiShow className="ms-2" onClick={togglePasswordShow} />}
                            </div>
                            <span className="my-1" role="alert">
                                {errors.password?.type === "required" && `Campo requerido`}
                                {errors.password?.type === "maxLength" && `Campo inválido`}
                            </span>

                            <label className="d-flex mb-1" htmlFor="confirmPassword">Confirmar contraseña</label>
                            <div className="d-flex align-items-center position-relative">
                                <input className="form-control" type={showPassword ? "text" : "password"} id="confirmPassword" autoComplete="off" {...register("confirmPassword", { required: true, maxLength: 50 })} maxLength={50} />
                                {showPassword ? <BiHide className="ms-2" onClick={togglePasswordShow}/> : <BiShow className="ms-2" onClick={togglePasswordShow} />}
                            </div>
                            <span className="my-1" role="alert">
                                {errors.confirmPassword?.type === "required" && `Campo requerido`}
                                {errors.confirmPassword?.type === "maxLength" && `Campo inválido`}
                            </span>

                            <button className="btn btn-danger mt-3" type="submit">Cambiar contraseña</button>
                        </form>
                        <div className="text-center mt-3">
                            <Link to="/login">Volver a Iniciar sesión</Link>
                        </div>
                    </NoAuthFormContainer>
                </MainContainer>
            }
        </>
    )
}

// ----- Styles
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    svg {
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        right: 4px;
    }
`;

const Logo = styled.img`
    height: 192px;
    margin: 0 auto 10px;
    width: 192px;
`;

export default ChangePassword;