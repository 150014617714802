export const formatCurrency = (value) => {
    return value ? new Intl.NumberFormat().format(value.toFixed(2)) : 0;
}

export const percentageFormat = (value) => {
    return new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
    }).format(value / 100);
}

export const getPercentage = (total, value) => {
    let percentage = ( value / total) * 100;
    return percentageFormat(percentage);
}

export const getTaxIncludedTotal = (details, tax) => {
    let total = 0;
    details.forEach(item => {
        total += (item.amount * item.itemsValue) + item.extraCharges - item.discount;
    })

    total = (1 + tax / 100) * total;

    return Number(total);
}

export const getSubTotal = (details) => {
    let total = 0;
    details.forEach(item => {
        total += (item.amount * item.itemsValue) + item.extraCharges - item.discount;
    })
    return Number(total);
}

export const getTotalItems = (details) => {
    let total = 0;
    details.forEach(item => {
        total += (item.amount * item.itemsValue);
    })
    return Number(total);
}

export const getTotalDiscount = (details) => {
    let total = 0;
    details.forEach(item => {
        total += item.discount;
    })
    return Number(total);
}

export const getTotalExtraCharges = (details) => {
    let total = 0;
    details.forEach(item => {
        total += item.extraCharges;
    })
    return Number(total);
}