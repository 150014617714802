import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getStock = async (token, page, size, sortField, filtersData) => {

    const url = sortField ? `${BASE_API_URL}/stock/search?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/stock/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getStock call: ', error);
            return error;
        });
}

export const searchArticleInStock = async (token, text) => {

    const url = `${BASE_API_URL}/stock/list`;

    return axios.post(url, text, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on searchArticleInStock call: ', error);
            return error;
        });
}
