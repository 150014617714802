export const checkSessionTime = (loggedInTime) => {
    const now = Date.now();
    let diffInMinutes = (now - loggedInTime) / 60000;

    if (diffInMinutes > 27) {
        return true;
    }

    return false;
}
