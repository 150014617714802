import { useState } from "react";

import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";

import { useAppContext } from "../contextApi/context";
import { getStock } from "../services/StockService";
import { exportStock } from "../services/ExportService";
import { createMovement } from "../services/StockMovementService";
import { formatCurrency } from "../utils/numbers";
import { checkPermission } from "../utils/permissions";
import { TableActionButton } from "../styles/StyledComponents";
import StockAdjustmentModal from "../components/shared/StockAdjustmentModal";
import ConfirmationAdjustmentModal from "../components/shared/ConfirmationAdjustmentModal";
import TableContainer from "../components/shared/TableContainer";

const Stock = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    let [showAdjustmentModal, setShowAdjustmentModal] = useState(false);
    let [adjustmentType, setAdjustmentType] = useState('');
    let [articleToAdjust, setArticleToAdjust] = useState(null);
    let [newAmount, setNewAmount] = useState(null);
    let [adjustmentDescription, setAdjustmentDescription] = useState(null);
    let [showAdjustmentCreated, setShowAdjustmentCreated] = useState(false);
    let [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Actions
    const openAdjustmentModal = (article, type) => {
        setAdjustmentType(type);
        setArticleToAdjust(article);
        setShowAdjustmentModal(true);
    }

    const handleModalAction = async (payload) => {
        const response = await createMovement(loggedUser.token, payload);

        if (response.status === 201) {
            setShowAdjustmentModal(false);
            setShowAdjustmentCreated(true);
            setUpdateTable(!updateTable);
        } else {
            // Error from API
            setNewAmount(payload.details[0].amount);
            setAdjustmentDescription(payload.description);
            setShowModalConfirmation(true);
        }
    }

    const handleContinue = async (payload) => {
        const response = await createMovement(loggedUser.token, payload);

        if (response.status === 201) {
            setShowModalConfirmation(false);
            setShowAdjustmentModal(false);
            setShowAdjustmentCreated(true);
            setUpdateTable(!updateTable);
        }
    }

    const handleCloseModal = () => {
        setShowAdjustmentModal(false);
    }

    const handleCloseConfirmationModal = () => {
        setShowModalConfirmation(false);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            {checkPermission(loggedUser.permissions, 'manage_stock_adjustment') &&
                <div className="d-flex">
                    <TableActionButton className="btn btn-primary" onClick={() => openAdjustmentModal(row, 'ADJUSTMENT_UP')}>
                        <FaArrowUp />
                    </TableActionButton>
                    <TableActionButton className="btn btn-danger" onClick={() => openAdjustmentModal(row, 'ADJUSTMENT_DOWN')}>
                        <FaArrowDown />
                    </TableActionButton>
                </div>
            }
        </div>
    );

    const columns = [
        {
            name: 'Artículo',
            selector: row => row.articleName,
            sortable: true,
            sortField: 'articleName',
        },
        {
            name: 'Marca',
            selector: row => row.brand,
            sortable: true,
            sortField: 'brand',
        },
        {
            name: 'Valor unitario',
            selector: row => `₡${formatCurrency(row.averageItemsValue)}`,
        },
        {
            name: 'Valor total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
        },
        {
            name: 'Cantidad',
            selector: row => row.amount,
            conditionalCellStyles: [
                {
                    when: row => row.amount === row.minimumAmount,
                    style: {
                        color: '#ffc107',
                        fontWeight: 'bold'
                    },
                },
                {
                    when: row => row.amount < row.minimumAmount,
                    style: {
                        color: '#dc3545',
                        fontWeight: 'bold'
                    },
                },
                {
                    when: row => row.amount > row.minimumAmount,
                    style: {
                        color: '#198754',
                        fontWeight: 'bold'
                    },
                },
            ],
        },
        {
            name: 'Acciones',
            width: "140px",
            cell: row => <Actions row={row} />,
            omit: !checkPermission(loggedUser.permissions, 'manage_stock_adjustment')
        },
    ];

    const filters = [
        {
            column: 'articleName',
            input: 'text',
            label: 'Artículo',
            operation: 'like',
            length: 50
        },
        {
            column: 'brand',
            input: 'text',
            label: 'Marca',
            operation: 'like',
            length: 25
        },
        {
            column: 'amount',
            input: 'range',
            label: 'Cantidad',
            operation: 'between',
            length: 10
        },
        {
            column: 'tag',
            input: 'tags',
            label: 'Etiquetas',
            operation: 'in',
        },
    ];

    const exportOptions = checkPermission(loggedUser.permissions, 'export_stock') ? {
        handler: exportStock
    } : null;

    // ----- Render
    return (
        <div className="container mt-5">
            {showAdjustmentCreated &&
                <div className="alert alert-success text-center" role="alert">
                    Ajuste realizado satisfactoriamente.
                </div>
            }

            <h1 className="">Inventario</h1>

            <TableContainer
                columns={columns}
                fetchDataHandler={getStock}
                updateTable={updateTable}
                filters={filters}
                exportOptions={exportOptions}
            />

            {showAdjustmentModal &&
                <StockAdjustmentModal
                    article={articleToAdjust}
                    type={adjustmentType}
                    closeHandler={handleCloseModal}
                    actionHandler={handleModalAction}
                    show={showAdjustmentModal}
                />
            }

            {showModalConfirmation &&
                <ConfirmationAdjustmentModal
                    closeHandler={handleCloseConfirmationModal}
                    actionHandler={handleContinue}
                    show={showModalConfirmation}
                    article={articleToAdjust}
                    type={adjustmentType}
                    amount={newAmount}
                    description={adjustmentDescription}
                />
            }

        </div>
    )
}

export default Stock;