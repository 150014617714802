import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { BiShow } from "react-icons/bi";
import { BiHide } from "react-icons/bi";

import { editUserProfile } from "../services/UsersService"
import { editPassword } from "../services/AuthService"
import { useAppContext } from "../contextApi/context"
import { CHANGED_PASSWORD_PASSWORD_MISMATCH, CHANGED_PASSWORD_WRONG_CURRENT_PASSWORD, USER_EMAIL_DUPLICATED } from "../constants/apiMessages"



const UserProfile = () => {
    // ----- Context/Hooks
    const { loggedUser, setLoggedUser } = useAppContext();

    // ----- State
    const [showUserProfileEdited, setShowUserProfileEdited] = useState(false);
    const [showErrorDuplicatedEmail, setShowErrorDuplicatedEmail] = useState(false);
    const [showPasswordEdited, setShowPasswordEdited] = useState(false);
    const [showErrorPasswordMismatch, setShowErrorPasswordsMismatch] = useState(false);
    const [showErrorCurrentPassword, setShowErrorCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
        }
    });

    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, reset: reset2 } = useForm({
        defaultValues: {
            // actualPassword: '',
            newPassword: '',
            passwordConfirmation: '',
        }
    });

    useEffect(() => {
        reset(loggedUser);
    }, [reset, loggedUser]);

    // ----- Actions
    const handleEdit = async (data) => {
        clearMessages();
        const response = await editUserProfile(loggedUser.token, data);

        if (response.status === 200) {
            let newLoggedUser = {
                ...loggedUser,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                email: response.data.email,
            }

            localStorage.removeItem("loggedUser");
            localStorage.setItem("loggedUser", JSON.stringify(newLoggedUser));
            setLoggedUser(newLoggedUser);

            setShowUserProfileEdited(true);
        } else {
            // Error from API
            const { data } = response;

            if (data[0] === USER_EMAIL_DUPLICATED) {
                setShowErrorDuplicatedEmail(true);
            }
        }
    }

    const handleChangePassword = async (data) => {
        clearMessages();
        const response = await editPassword(loggedUser.token, data);

        if (response.status === 200) {
            reset2();
            setShowPasswordEdited(true);
        } else {
            // Error from API
            const { data } = response;

            if (data === CHANGED_PASSWORD_PASSWORD_MISMATCH) {
                setShowErrorPasswordsMismatch(true);
            }

            if (data === CHANGED_PASSWORD_WRONG_CURRENT_PASSWORD) {
                setShowErrorCurrentPassword(true);
            }
        }
    }

    const togglePasswordShow = () => {
        setShowPassword(!showPassword)
    }

    const clearMessages = () => {
        setShowUserProfileEdited(false);
        setShowErrorDuplicatedEmail(false);
        setShowPasswordEdited(false);
        setShowErrorPasswordsMismatch(false);
        setShowErrorCurrentPassword(false);
    }

    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="mb-5">Perfil de Usuario</h1>
            <h3 className="mb-3">Datos generales</h3>

            {showUserProfileEdited &&
                <div className="alert alert-success text-center mb-0" role="alert">
                    Datos de usuario editados satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedEmail &&
                <div className="alert alert-danger text-center mb-0" role="alert">
                    El correo electrónico ya se encuentran registrado
                </div>
            }

            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="firstName">Nombre</label>
                    <input className="form-control" type="text" id="firstName" autoComplete="off" {...register("firstName", { required: true, maxLength: 50 })} maxLength={50} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.firstName?.type === "required" && `Campo requerido`}
                        {errors.firstName?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="lastName">Apellido</label>
                    <input className="form-control" type="text" id="lastName" autoComplete="off" {...register("lastName", { required: true, maxLength: 50 })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.lastName?.type === "required" && `Campo requerido`}
                        {errors.lastName?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="email">Correo electrónico</label>
                    <input className="form-control" type="text" id="email" autoComplete="off" {...register("email", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.email?.type === "required" && `Campo requerido`}
                        {errors.email?.type === "maxLength" && `Formato inválido`}
                        {errors.email?.type === "pattern" && `Formato de correo incorrecto`}
                    </span>

                    <button className="btn btn-danger mt-3" type="submit">Editar</button>

                </form>
            </FormContainer>

            <hr className="mb-5" />

            <h3 className="mb-3">Cambiar contraseña</h3>
            {showPasswordEdited &&
                <div className="alert alert-success text-center mb-0" role="alert">
                    Contraseña editada satisfactoriamente.
                </div>
            }

            {showErrorPasswordMismatch &&
                <div className="alert alert-danger text-center mb-0" role="alert">
                    Las nuevas contraseñas no coinciden.
                </div>
            }

            {showErrorCurrentPassword &&
                <div className="alert alert-danger text-center mb-0" role="alert">
                    Contraseña actual incorrecta.
                </div>
            }

            <FormContainer>
                <form onSubmit={handleSubmit2(handleChangePassword)}>
                    <label className="d-flex mb-1" htmlFor="currentPassword">Contraseña actual</label>
                    <div className="d-flex align-items-center">
                        <input className="form-control" type={showPassword ? "text" : "password"} id="currentPassword" {...register2("currentPassword", { required: true, maxLength: 50 })} maxLength={50} autoComplete="off" />
                        {showPassword ? <BiHide className="ms-2" onClick={togglePasswordShow} /> : <BiShow className="ms-2" onClick={togglePasswordShow} />}
                    </div>
                    <span className="my-1" role="alert">
                        {errors2.currentPassword?.type === "required" && `Campo requerido`}
                        {errors2.currentPassword?.type === "maxLength" && `Campo inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="newPassword">Nueva contraseña</label>
                    <div className="d-flex align-items-center">
                        <input className="form-control" type={showPassword ? "text" : "password"} id="newPassword" {...register2("newPassword", { required: true, maxLength: 50 })} maxLength={50} autoComplete="off" />
                        {showPassword ? <BiHide className="ms-2" onClick={togglePasswordShow} /> : <BiShow className="ms-2" onClick={togglePasswordShow} />}
                    </div>
                    <span className="my-1" role="alert">
                        {errors2.newPassword?.type === "required" && `Campo requerido`}
                        {errors2.newPassword?.type === "maxLength" && `Campo inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="passwordConfirmation">Confirmar nueva contraseña</label>
                    <div className="d-flex align-items-center">
                        <input className="form-control" type={showPassword ? "text" : "password"} id="passwordConfirmation" autoComplete="off" {...register2("passwordConfirmation", { required: true, maxLength: 50 })} maxLength={50} />
                        {showPassword ? <BiHide className="ms-2" onClick={togglePasswordShow} /> : <BiShow className="ms-2" onClick={togglePasswordShow} />}
                    </div>
                    <span className="my-1" role="alert">
                        {errors2.passwordConfirmation?.type === "required" && `Campo requerido`}
                        {errors2.passwordConfirmation?.type === "maxLength" && `Campo inválido`}
                    </span>

                    <button className="btn btn-danger mt-3" type="submit">Cambiar contraseña</button>
                </form>
            </FormContainer>

        </div>
    )
}

const FormContainer = styled.div`
    margin: 0px auto;
    padding: 40px;
    width: 60%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        > button {
            background-color: #D04437;
        }

        svg {
            cursor: pointer;
            font-size: 20px;
        }
    }
`;

export default UserProfile;