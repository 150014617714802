import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { getArticle, editArticle, addTag, removeTag, getUnits } from "../services/ArticlesService"
import { searchTagsByName } from "../services/TagsService"
import { useAppContext } from "../contextApi/context"
import TagChip from "../components/shared/TagChip"
import AssignProvider from "../components/AssignProvider"
import { ARTICLE_CODE_DUPLICATED } from "../constants/apiMessages"
import { measureUnit } from "../utils/translation"

const EditArticle = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { id } = useParams();

    // ----- State
    let [measureUnits, setMeasureUnits] = useState([])
    let [availableTags, setAvailableTags] = useState([]);
    const [currentArticle, setCurrentArticle] = useState(null);
    const [timer, setTimer] = useState(null);
    const [selectedTag, setSelectedTag] = useState("");
    const [showArticleEdited, setShowArticleEdited] = useState(false);
    const [showErrorOnEdit, setShowErrorOnEdit] = useState(false);
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false);
    const [errorDuplicatedData, setErrorDuplicatedData] = useState("");

    // ----- API Fetch Actions
    const fetchArticleData = useCallback(async () => {
        return await getArticle(loggedUser.token, id);
    }, [loggedUser.token, id]);

    const fetchUnitsData = useCallback(async () => {
        return await getUnits(loggedUser.token);
    }, [loggedUser.token]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            description: '',
            internalCode: '',
            minimumAmount: '',
            measureUnit: ''
        }
    });

    useEffect(() => {
        handleUpdateArticle();

        fetchUnitsData().then(data => {
            setMeasureUnits(data);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchUnitsData]);


    // ----- Actions
    const handleUpdateArticle = () => {
        fetchArticleData().then(data => {
            setCurrentArticle(data);
            reset(data);
        });
    }

    const handleEdit = async (data) => {
        clearMessages();
        const response = await editArticle(loggedUser.token, currentArticle.id, data);

        if (response.status === 200) {
            setShowArticleEdited(true);
        } else {
            // Error from API
            const { data } = response;
            let errorMessageDuplicated = "El artículo contiene datos que ya se encuentran registrados:";

            if (data.includes(ARTICLE_CODE_DUPLICATED)) {
                errorMessageDuplicated += " Código,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);
        }
    }

    const handleAddTag = async () => {
        clearMessages();

        if (selectedTag !== "") {
            let response = await addTag(loggedUser.token, currentArticle.id, selectedTag);
            if (response.status === 200) {
                setCurrentArticle(response.data);
                setSelectedTag('');
            }
        }
    }

    const handleRemoveTag = async (tagId, _name) => {
        let response = await removeTag(loggedUser.token, currentArticle.id, tagId);

        if (response.status === 200) {
            setCurrentArticle(response.data);
        }
    }

    const handleSelectOption = (e, newValue, reason) => {
        clearTimeout(timer)

        const newTimer = setTimeout(async () => {

            if (reason === 'clear') {
                setAvailableTags([]);
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    const response = await searchTagsByName(loggedUser.token, newValue);
                    const tagExists = response.find(e => e.name === newValue);
                    const parsedResponse = response?.map(tag => ({ id: tag.id, label: tag.name, value: tag.name }));
                    const finalOptions = tagExists ? parsedResponse : [{ label: '- Etiqueta nueva: ' + newValue, value: newValue }, ...parsedResponse];

                    setAvailableTags(finalOptions);
                }
            }
            else {
                setAvailableTags([]);
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleChange = (event, newValue) => {
        if (newValue != null) {
            setSelectedTag(newValue.value)
        }
    }

    const clearMessages = () => {
        setShowArticleEdited(false);
        setShowErrorOnEdit(false);
        setShowErrorDuplicatedData(false);
    }

    // ----- Render
    return (
        <div className="container mt-4">
            <div className="mb-3">
                <Link to="/articulos">Volver a página de artículos</Link>
            </div>
            <h1 className="mt-4">Editar Artículo</h1>

            {showArticleEdited &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Artículo editado satisfactoriamente.
                </div>
            }

            {showErrorOnEdit &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    Artículo no editado.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }


            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                    <input className="form-control" type="text" id="name" autoComplete="off" maxLength={50} {...register("name", { required: true, maxLength: 50 })} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.name?.type === "required" && `Campo requerido`}
                        {errors.name?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="internalCode">Código</label>
                    <input className="form-control" type="text" id="internalCode" autoComplete="off" maxLength={50} {...register("internalCode", { required: true, maxLength: 50 })} />
                    <span className="my-1" role="alert">
                        {errors.internalCode?.type === "required" && `Campo requerido`}
                        {errors.internalCode?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="minimumAmount">Cantidad mínima</label>
                    <input className="form-control" type="text" id="minimumAmount" autoComplete="off" maxLength={10} {...register("minimumAmount", { required: true, pattern: /^\d*\.?\d*$/, maxLength: 10 })} />
                    <span className="my-1" role="alert">
                        {errors.minimumAmount?.type === "required" && `Campo requerido`}
                        {errors.minimumAmount?.type === "maxLength" && `Formato inválido`}
                        {errors.minimumAmount?.type === "pattern" && `Sólo se permiten números (5 - 3.5)`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="measureUnit">Unidad de medida</label>
                    <select className="form-select" {...register("measureUnit", { required: true })}>
                        <option value="">Seleccione una medida</option>
                        {measureUnits?.map((unit, index) =>
                            (<option selected={unit === currentArticle?.measureUnit} key={index} value={unit}>{measureUnit(unit)}</option>)
                        )}
                    </select>
                    <span className="my-1" role="alert">
                        {errors.measureUnit && `Campo requerido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                    <textarea className="form-control" id="description" maxLength={100} rows="3" {...register("description", { maxLength: 100 })} />
                    <span className="my-1" role="alert">
                        {errors.description?.type === "maxLength" && `Formato inválido`}
                    </span>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar cambios</button>
                    </div>
                </form>
            </FormContainer>

            <hr />

            <TagsContainer>
                <h1>Etiquetas</h1>

                <div className="row my-4">
                    <div className="col pe-5">
                        <h5 className="mt-3">Agregar etiquetas</h5>

                        <Autocomplete
                            value={selectedTag}
                            disablePortal
                            id="size-small-outlined"
                            size="small"
                            options={availableTags}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, maxLength: 50 }} label="Buscar o crear etiqueta" />}
                            onChange={handleChange}
                            onInputChange={handleSelectOption}
                            className="mt-4 mb-3"
                            freeSolo
                        />

                        <button className="btn btn-danger" onClick={handleAddTag}>Agregar</button>
                    </div>
                </div>

                <div className="d-flex mb-1">
                    {currentArticle?.tags.map(tag =>
                        <TagChip key={tag.id} removeHandler={handleRemoveTag} name={tag.name} id={tag.id} />
                    )}
                </div>
            </TagsContainer>

            <hr />
            <AssignProvider updateHandler={handleUpdateArticle} articleId={currentArticle?.id} providers={currentArticle?.providers === null ? [] : currentArticle?.providers} />

        </div>
    )
}

const FormContainer = styled.div`
    margin: 25px auto 0;
    padding: 40px 40px 20px;
    width: 70%;

    form {
        display: grid;

        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

const TagsContainer = styled.div`
    padding: 15px 0;

    span {
        color: #D04437;
        font-size: 13px;
        min-height: 12px;
    }
`

export default EditArticle;