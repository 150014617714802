import { useCallback, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";

import { useAppContext } from "../contextApi/context";
import { getMovementDetails, editReturnMovement } from "../services/StockMovementService";

const EditMovementReturnModal = ({ closeHandler, actionHandler, returnId, show }) => {
    // ----- Context
    const { loggedUser } = useAppContext();

    // ----- State
    let [movementDetails, setMovementDetails] = useState([]);
    let [originalAmounts, setOriginalAmounts] = useState([]);
    let [allGood, setAllGood] = useState(true);

    // ----- API Fetch Actions
    const fetchStockMovementDetails = useCallback(async (id) => {
        return await getMovementDetails(loggedUser.token, id);
    }, [loggedUser.token]);

    // ----- Hooks
    useEffect(() => {
        fetchStockMovementDetails(returnId).then(returnData => {

            let details = returnData.details.map((item) => {
                return {
                    id: item.id,
                    articleId: item.articleId,
                    articleName: item.articleName,
                    brand: item.brand,
                    amount: item.amount,
                    amountToReturn: null,
                    showError: false
                }
            });
            setMovementDetails(details);

            // Getting issueData based on returnData
            fetchStockMovementDetails(returnData.reversedMovement).then(issueData => {
                let originals = issueData.details.map((item) => item.amount);
                setOriginalAmounts(originals);
            })
        })

    }, [fetchStockMovementDetails, returnId]);

    // ----- Actions
    const handleClose = () => {
        setMovementDetails(null);
        closeHandler();
    }

    const handleAction = async () => {
        let details = movementDetails.map(item => {
            return {
                id: item.id,
                articleId: item.articleId,
                amount: item.amountToReturn === null ? item.amount : item.amountToReturn,
                brand: item.brand,
            }
        });

        const response = await editReturnMovement(loggedUser.token, returnId, details);
        if (response.status === 201) {
            actionHandler();
        } else {
            // Error from API
        }
    }

    const handleInputChange = (detailIndex, e) => {
        let amountToReturn = e.target.value;

        let newMovementDetails = movementDetails.map((item, index) => {
            if (index === detailIndex) {
                if (amountToReturn === '' || Number(amountToReturn) > originalAmounts[index]) {
                    item.showError = true
                }
                else {
                    item.showError = false
                    item.amountToReturn = Number(amountToReturn);
                }
            }
            return item;
        })

        setMovementDetails(newMovementDetails);
        setAllGood(!newMovementDetails.some(e => e.showError))
    }

    const handleKeyDown = (e) => {
        const acceptedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'ArrowLeft', 'ArrowRight', 'Backspace'];
        if (!acceptedKeys.includes(e.key)) {
            e.preventDefault()
        }
    }

    // ----- Render
    return (
        <Modal show={show} size="lg" backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                Editar devolución
            </Modal.Header>
            <Modal.Body>
                {movementDetails && <>
                    <div className="d-flex flex-column">
                        <label className="fw-bold">Detalles</label>
                        <p className="mb-1 ms-2">Nota: Las cantidades a devolver no pueden ser mayores a la cantidad original del despacho.</p>

                        <table className="table table-bordered my-2">
                            <thead>
                                <tr>
                                    <th scope="col">Artículo</th>
                                    <th scope="col">Cantidad original</th>
                                    <th scope="col">Devolución</th>
                                </tr>
                            </thead>
                            <tbody>
                                {movementDetails.map((item, index) =>
                                (
                                    <tr key={index}>
                                        <td>{`${item.articleName} (${item.brand})`}</td>
                                        <td>{originalAmounts[index]}</td>
                                        <ReturnColumn>
                                            <input
                                                type="text"
                                                defaultValue={item.amount}
                                                maxLength={5}
                                                onKeyDown={handleKeyDown}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            <span className="ms-2">
                                                {item.showError && 'x'}
                                            </span>
                                        </ReturnColumn>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <ModalButton className="cancel" onClick={handleClose}>
                    Cancelar
                </ModalButton>
                <ModalButton className="action" onClick={handleAction} disabled={!allGood}>
                    Guardar
                </ModalButton>
            </Modal.Footer>
        </Modal>
    );
}

const ReturnColumn = styled.td`
    align-items: center;
    display: flex;
    flex-direction: row;
    
    > input {
        width: 70px;
    }

    > span {
        color: #D04437;
        font-size: 16px;
        font-weight: bold;
        margin-left: .5rem;
    }
`;

const ModalButton = styled(Button)`
    border: none;

    &.cancel {
        background-color: #A5A7A0;
    }
    
    &.action {
        background-color: #D04437;
    }
`;

export default EditMovementReturnModal;
