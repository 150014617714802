// Authorization
export const BLOCKED_BY_CHANGE_PASSWORD = 'User requested a password change';
export const FORGOT_PASSWORD_SENT = 'Reset instructions sent';
export const CHANGED_PASSWORD_USER_NOT_FOUND = 'User not found';
export const CHANGED_PASSWORD_WRONG_USER = 'Password change not requested';
export const CHANGED_PASSWORD_WRONG_TOKEN = 'Wrong password change token';
export const CHANGED_PASSWORD_PASSWORD_MISMATCH = 'Password mismatch';
export const CHANGED_PASSWORD_WRONG_CURRENT_PASSWORD = 'Wrong current password';

// CRUDS
export const USER_USERNAME_DUPLICATED = "username: User's username already exists";
export const USER_EMAIL_DUPLICATED = "email: User's email already exists";

export const ROLE_DELETE_ASSIGNED = 'There are users with this role assigned';
export const ROLE_NAME_DUPLICATED = "name: Role's name already exists";

export const TAG_NAME_DUPLICATED = "name: Tag's name already exists";

export const ARTICLE_CODE_DUPLICATED = "internalCode: Article's internal code already exists";
export const ARTICLE_ASSOCIATED_WITH_STOCK = "stock: there is stock associated with this Article";

export const VEHICLE_PLATE_DUPLICATED = "plate: Vehicle's plate already exists";

export const PROVIDER_CODE_DUPLICATED = "code: Provider's code already exists";
export const PROVIDER_ID_DUPLICATED = "identification: Provider's identification already exists";
export const PROVIDER_PHONE_DUPLICATED = "contactPhone: Provider's contact's phone already exists";
export const PROVIDER_EMAIL_DUPLICATED = "email: Provider's email already exists";
export const PROVIDER_CONTACT_EMAIL_DUPLICATED = "contactEmail: Provider's contact's email already exists";
export const PROVIDER_ASSOCIATED_WITH_STOCK = "stock movements: there are stock movements associated with this Provider's articles";

export const PROVIDER_ARTICLE_WITH_STOCK = "stock movements: there are stock movements associated with this Article-Provider";
export const PROVIDER_ARTICLE_INTERNAL_CODE_DUPLICATED = "internalProviderCode: Internal provider code already exists";
export const PROVIDER_ARTICLE_BRAND_DUPLICATED = "brand: Brand already exists for article and provider";
