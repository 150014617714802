import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { styled } from "styled-components"
import { useForm } from "react-hook-form"

import { useAppContext } from "../contextApi/context";
import { forgotPassword } from "../services/AuthService";
import { FORGOT_PASSWORD_SENT } from "../constants/apiMessages"
import logo from "../assets/img/logo-192x192.png"
import { NoAuthFormContainer } from "../styles/StyledComponents"


const ForgotPassword = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();

    // ----- Actions
    const goToDashboard = () => navigate("/dashboard")

    // ----- State
    const [showError, setShowError] = useState(false);

    const handleResetPassword = async (data) => {
        setShowError(false);

        const { username } = data;
        const response = await forgotPassword(username);

        if (response === FORGOT_PASSWORD_SENT) {
            navigate("/cambiar-contrasena");
        } else if (response.response.status === 400) {
            setShowError(true);
        }
        else {
            // Error from API
        }
    }

    // ----- Render
    return (
        <>
            {loggedUser ? goToDashboard() :
                <MainContainer className="container">
                    <Logo src={logo} />
                    <NoAuthFormContainer className="shadow card p-4">
                        {showError &&
                            <div className="alert alert-danger text-center mb-1" role="alert">
                                Usuario inválido.
                            </div>
                        }

                        <h2 className="mb-4 text-center">Recuperar contraseña</h2>
                        <p className="text-muted text-center mb-4">Ingrese su nombre de usuario para recibir las instrucciones de recuperar contraseña.</p>

                        <form onSubmit={handleSubmit(handleResetPassword)}>
                            <label className="d-flex mb-1" htmlFor="username">Nombre de usuario</label>
                            <input maxLength={50} className="form-control" type="text" id="username" {...register("username", { required: true, maxLength: 50 })} autoFocus autoComplete="off" />
                            <span className="my-1" role="alert">
                                {errors.username?.type === "required" && `Campo requerido`}
                                {errors.username?.type === "maxLength" && `Campo inválido`}
                            </span>

                            <button type="submit" className="btn btn-danger mt-3">Enviar</button>
                        </form>
                        <div className="text-center mt-3">
                            <Link to="/login">Volver a Iniciar sesión</Link>
                        </div>
                    </NoAuthFormContainer>
                </MainContainer>
            }
        </>
    )
}

// ----- Styles
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

const Logo = styled.img`
    height: 192px;
    margin: 0 auto 20px;
    width: 192px;
`;

export default ForgotPassword;