
import { styled } from "styled-components";

// No Auth pages
export const NoAuthFormContainer = styled.div`
    background-color: #f5f7fa;
    margin: 0 auto;
    width: 55%;

    form {
        display: grid;
        
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        > button {
            background-color: #D04437;
        }
    }
`;

// Cruds
export const AddButton = styled.button`
    align-items: center;
    background-color: #A5A7A0;
    border-color: #A5A7A0;
    display: flex;

    &:hover {
        background-color: #A5A7A0;
        border-color: #A5A7A0;
    }

    svg {
        font-size: 18px;
        margin-right: 6px;
    }
`;

export const ExportContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 30px;
    
    > button {
        align-items: center;
        border: 1px solid #A5A7A0;
        display: flex;

        &:hover {
            border: 1px solid #A5A7A0;
        }
            
        > svg {
            font-size: 18px;
            margin-right: 8px;
        }
    }
`;

export const TableActionButton = styled.button`
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin-right: 8px;
    padding: 4px 8px;

    &.edit {
        background-color: #A5A7A0;
        border-color: #A5A7A0;   
    }

    svg {
        color: #FFFFFF;
        font-size: 16px;
        margin-right: 2px;
    }

    > a {
        color: #FFFFFF;
        text-decoration: none;
    }
`;

// Reports

// <PercentageContainer color={'#dc3545'}>
export const PercentageContainer = styled.div`
    align-items: center;
    border: 3px solid ${(props) => props.color};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 25px auto 10px;
    width: 28%;

    > div {
        background-color: ${(props) => props.color};
        color: #FFF;
        font-weight: bold;
        font-size: 20px;
        padding: 12px;
        text-align: center;
        width: 100%;
    }
    
    > p {
        color: ${(props) => props.color};
        font-size: 45px;
        font-weight: bold;
        margin-bottom: 0;
        padding: 30px 0 40px;
    }
`;