import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getVehicles = async (token, page, size, sortField, filtersData) => {

    const url = sortField ? `${BASE_API_URL}/vehicles/search?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/vehicles/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getVehicles call: ', error);
            return error;
        });
}

export const getVehicle = async (token, id) => {
    const url = `${BASE_API_URL}/vehicles/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getVehicle call: ', error);
        return error;
    })
}

export const createVehicle = async (token, data) => {
    const url = `${BASE_API_URL}/vehicles`;
    const payload = {
        plate: data.plate,
        vin: data.vin,
        make: data.make,
        model: data.model,
        year: data.year,
        color: data.color,
        details: data.details,
        odometer: data.odometer,
        type: data.type
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createVehicle call: ', error);
        return error.response;
    });
}

export const editVehicle = async (token, articleId, data) => {
    const url = `${BASE_API_URL}/vehicles/${articleId}`;
    const payload = {
        plate: data.plate,
        vin: data.vin,
        make: data.make,
        model: data.model,
        year: data.year,
        color: data.color,
        details: data.details,
        odometer: data.odometer,
        type: data.type,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editVehicle call: ', error);
        return error.response;
    });
}

export const deleteVehicle = async (token, articleId) => {
    const url = `${BASE_API_URL}/vehicles/${articleId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteVehicle call: ', error);
        return error.response;
    });
}

export const searchVehiclesByPlate = async (token, text) => {
    const url = `${BASE_API_URL}/vehicles/list`;

    return axios.post(url, text, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on searchVehiclesByPlate call: ', error);
            return error;
        });
}
