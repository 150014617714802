import Modal from 'react-bootstrap/Modal';
import { styled } from "styled-components";
import { useForm } from "react-hook-form"

const EditAssignmentModal = ({ title, show, assignment, closeHandler, actionHandler }) => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            internalProviderCode: assignment.internalProviderCode,
            brand: assignment.brand,
        }
    });

    // ----- Actions
    const handleClose = () => {
        closeHandler();
    }

    const handleAction = (data) => {
        const payload = {
            id: assignment.id,
            articleId: assignment.articleId,
            providerId: assignment.providerId,
            internalProviderCode: data.internalProviderCode,
            brand: data.brand
        }
        actionHandler(payload);
    }

    // ----- Render
    return (
        <Modal show={show} backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                {title}
            </Modal.Header>
            <Modal.Body>
                <FormStyled onSubmit={handleSubmit(handleAction)}>
                    <label className="d-flex mb-1" htmlFor="brand">Marca</label>
                    <input className="form-control" type="text" id="brand" autoComplete="off" maxLength={25} {...register("brand", { required: true, maxLength: 25 })} />
                    <span className="my-1" role="alert">
                        {errors.brand?.type === "required" && `Campo requerido`}
                        {errors.brand?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="internalProviderCode">Código interno</label>
                    <input className="form-control" type="text" id="internalProviderCode" autoComplete="off" maxLength={25} {...register("internalProviderCode", { required: true, maxLength: 25 })} />
                    <span className="my-1" role="alert">
                        {errors.internalProviderCode?.type === "required" && `Campo requerido`}
                        {errors.internalProviderCode?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <button className="btn btn-danger mt-3" type="submit">Editar</button>
                </FormStyled>
            </Modal.Body>
        </Modal>
    );
}

const FormStyled = styled.form`
    display: grid;

    > label {
        font-size: 13px;
        font-weight: bold;
    }

    > span {
        color: #D04437;
        font-size: 12px;
        min-height: 12px;
    }

    > button {
        background-color: #D04437;
    }
`;

export default EditAssignmentModal;
