export const movementType = (type) => {
    switch (type) {
        case "RECEIPT":
            return 'Ingreso';
        case "ISSUE":
            return 'Salida';
        case "ADJUSTMENT_UP":
            return 'Ajuste hacia arriba';
        case "ADJUSTMENT_DOWN":
            return 'Ajuste hacia abajo';
        case "RETURN":
            return 'Devolución';
        case "TRANSFER":
            return 'Transferencia';
        default:
            break;
    }
}

export const measureUnit = (unit) => {
    switch (unit) {
        case "UNITS":
            return 'Unidad';
        case "CM":
            return 'Centímetros';
        case "M":
            return 'Metros';
        case "KG":
            return 'Kilos';
        case "L":
            return 'Litros';
        case "GAL":
            return 'Galones';
        default:
            break;
    }
}

export const vehicleType = (type) => {
    switch (type) {
        case "CARGO":
            return 'De carga';
        case "LIGHT":
            return 'Liviano';
        case "YELLOW":
            return 'Amarillo';
        default:
            break;
    }
}
