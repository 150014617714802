import { useCallback, useEffect, useState } from 'react';
import { styled } from "styled-components";

import { getStockFinancialValue, getStockFinancialByTags } from "../services/ReportsService";
import { useAppContext } from "../contextApi/context";
import { formatCurrency, percentageFormat } from "../utils/numbers";
import TableContainer from '../components/shared/TableContainer';

const ReportFinancial = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    const [financialTotalValue, setFinancialTotalValue] = useState(null);

    // ----- API Fetch Actions
    const fetchStockFinancialValueData = useCallback(async () => {
        return await getStockFinancialValue(loggedUser.token);
    }, [loggedUser.token]);

    // ----- Hooks
    useEffect(() => {
        fetchStockFinancialValueData().then(data => {
            setFinancialTotalValue(data);
        })
    }, [fetchStockFinancialValueData]);

    // ----- Constants
    const columns = [
        {
            name: 'Etiqueta',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Valor',
            id: 'tagValue',
            selector: row => `₡${formatCurrency(row.tagValue)}`,
            sortable: true,
            sortField: 'tagValue',
        },
        {
            name: 'Porcentaje',
            selector: row => `${percentageFormat(row.tagValuePercent)}`,
            sortable: true,
            sortField: 'tagValuePercent',
        }
    ];

    const filters = [
        {
            column: 'name',
            input: 'text',
            label: 'Etiqueta',
            operation: 'like',
            length: 50
        }
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="mb-5">Reporte Financiero</h1>

            <div className='d-flex justify-content-center mb-5'>
                <FinancialContainer color={'#198754'}>
                    <div>Total de activos</div>
                    <p>{`₡${formatCurrency(financialTotalValue || 0)}`}</p>
                </FinancialContainer>
            </div>

            <h3 className="mb-5">Detalle Financiero por Categoría</h3>

            <TableContainer
                columns={columns}
                fetchDataHandler={getStockFinancialByTags}
                updateTable={true}
                filters={filters}
                defaultSort={'tagValue'}
            />

        </div>
    )
}

const FinancialContainer = styled.div`
    align-items: center;
    border: 3px solid ${(props) => props.color};;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 25px 0 10px;
    min-width: 500px;
    width: auto;

    > div {
        background-color: ${(props) => props.color};;
        color: #FFF;
        font-weight: bold;
        font-size: 26px;
        padding: 12px;
        text-align: center;
        width: 100%;
    }
    
    > p {
        color: ${(props) => props.color};;
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 0;
        padding: 60px 50px;
    }
`;

export default ReportFinancial;