import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getUsers = async (token, page, size, sortField, filtersData) => {

    const url = sortField ? `${BASE_API_URL}/users/search?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/users/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getUsers call: ', error);
        return error;
    });
}

export const getUser = async (token, id) => {
    const url = `${BASE_API_URL}/users/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getUser call: ', error);
        return error;
    })
}

export const createUser = async (token, data) => {
    const url = `${BASE_API_URL}/users`;
    const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        username: data.username,
        password: data.password,
        roleId: Number(data.role)
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createUser call: ', error);
        return error.response;
    });
}

export const editUser = async (token, data) => {
    const url = `${BASE_API_URL}/users/${data.id}`;
    const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        username: data.username,
        roleId: Number(data.roleId)
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editUser call: ', error);
        return error.response;
    });
}

export const editUserProfile = async (token, data) => {
    const url = `${BASE_API_URL}/users/update-profile`;
    const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editUserProfile call: ', error);
        return error.response;
    });
}

export const deleteUser = async (token, userId) => {
    const url = `${BASE_API_URL}/users/${userId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteUser call: ', error);
        return error;
    });
}

export const searchUsersByName = async (token, text) => {
    const url = `${BASE_API_URL}/users/list`;

    return axios.post(url, text, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on searchUsersByName call: ', error);
            return error;
        });
}