import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const login = async (username, password) => {
    const url = `${BASE_API_URL}/users/authenticate`;

    return axios.post(url, {
        username,
        password
    })
        .then((response) => {
            const { data } = response;

            let loggedUserInfo = {
                token: data.token,
                firstName: data.user.firstName,
                lastName: data.user.lastName,
                email: data.user.email,
                username: data.user.username,
                roleId: data.user.roleId,
                permissions: data.user.permissions
            }
            return loggedUserInfo;
        }).catch(error => {
            console.log('Error on login call: ', error);
            return error.response;
        });
}

export const forgotPassword = async (username) => {
    const url = `${BASE_API_URL}/users/request-password-change`;

    return axios.post(url, {
        username
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on forgotPassword call: ', error);
            return error;
        });
}

export const changePassword = async (username, passwordChangeToken, newPassword, passwordConfirmation) => {
    const url = `${BASE_API_URL}/users/reset-password`;

    return axios.post(url, {
        username,
        passwordChangeToken,
        newPassword,
        passwordConfirmation
    })
        .then((response) => {
            return response;
        }).catch(error => {
            console.log('Error on changePassword call: ', error);
            return error.response;
        });
}

export const renewSession = async (token) => {
    const url = `${BASE_API_URL}/users/authentication/renew`;

    return axios.post(url, {}, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;

            let loggedUserInfo = {
                token: data.token,
                firstName: data.user.firstName,
                lastName: data.user.lastName,
                email: data.user.email,
                username: data.user.username,
                roleId: data.user.roleId,
                permissions: data.user.permissions
            }
            return loggedUserInfo;
        }).catch(error => {
            console.log('Error on renewSession call: ', error);
            return error;
        });
}

export const editPassword = async (token, data) => {
    const url = `${BASE_API_URL}/users/update-password`;
    const payload = {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        passwordConfirmation: data.passwordConfirmation
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editPassword call: ', error);
        return error.response;
    });
}