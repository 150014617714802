import { styled } from "styled-components"

const TagChip = ({ name, id = "", removeHandler }) => {

    // ----- Render
    return (
        <TagPill className="badge bg-light">
            <button className="rounded-circle" onClick={() => removeHandler(id, name)}>X</button>
            {name}
        </TagPill>
    );
}

// ----- Styles
const TagPill = styled.div`
    align-items: center;
    border: 1px solid #000;
    border-radius: 50rem;
    color: #000;
    display: flex;
    font-size: 14px;
    margin-right: 4px;
    
    button{
        align-items: center;
        background: none;
        border: 2px solid #000;
        color: #000;
        display: flex;
        font-size: 12px;
        font-weight: bold;
        height: 20px;
        justify-content: center;
        width: 20px;
        margin-right: 5px;
    }
`

export default TagChip;
