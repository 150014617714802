import { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";

const TimeoutModal = ({ show, continueHandler, logoutHandler }) => {
    // ----- State
    const [time, setTime] = useState(localStorage.getItem("logoutModalSeconds") || 120);
    const [timer, setTimer] = useState(null);

    let flagged = false;

    useEffect(() => {

        if (!flagged) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            flagged = true;
            setTimer(
                setInterval(() => {
                    let intervalTime = localStorage.getItem("logoutModalSeconds") || 120;

                    if (Number(intervalTime) === 0) {
                        const intervalId = setInterval(function () { }, Number.MAX_SAFE_INTEGER);

                        for (let i = 0; i < intervalId; i++) {
                            clearInterval(i);
                        }
                        handleLogout();
                    } else {
                        const now = Date.now();
                        let loggedInTime = localStorage.getItem("loggedInTime");
                        let diffInMinutes = (now - loggedInTime) / 60000;

                        if (diffInMinutes >= 29) {
                            handleLogout();
                        } else {
                            intervalTime = intervalTime - 1;
                            setTime((state) => state - 1);
                            localStorage.setItem("logoutModalSeconds", intervalTime);
                        }
                    }
                }, 1000)
            )
        };

    }, []);

    // ----- Actions
    const handleContinue = () => {
        clearInterval(timer);
        localStorage.setItem("logoutModalSeconds", 120);
        continueHandler();
    }

    const handleLogout = () => {
        clearInterval(timer);
        setTime(0);
        localStorage.removeItem("logoutModalSeconds");
        logoutHandler();
    }

    // ----- Render
    return (
        <Modal show={show} backdrop="static" centered>
            <Modal.Header className='fw-bold'>
                Sesión a punto de expirar
            </Modal.Header>
            <Modal.Body>
                <p>
                    Tiempo restante:
                    <b>
                        {` ${Math.floor(time / 60)}`.padStart(2, 0)}:{`${time % 60}`.padStart(2, 0)}
                    </b>
                </p>
                <p className='mb-0'>Desea continuar?</p>
            </Modal.Body>
            <Modal.Footer>
                <ModalButton onClick={handleContinue}>
                    Continuar
                </ModalButton>
            </Modal.Footer>
        </Modal>
    );
}

const ModalButton = styled(Button)`
    background-color: #D04437;
    border: none;

    &:hover {
        background-color: #D04437;
    }
`;

export default TimeoutModal;
