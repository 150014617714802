import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { FaMagnifyingGlass } from "react-icons/fa6";
import { GrRevert } from "react-icons/gr";
import { FaPlus } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";

import { useAppContext } from '../contextApi/context';
import { getIssueMovements } from "../services/StockMovementService";
import StockMovementDetailsModal from "../components/shared/StockMovementDetailsModal";
import MovementReturnModal from "../components/MovementReturnModal";
import EditMovementReturnModal from "../components/EditMovementReturnModal";
import { TableActionButton, AddButton } from "../styles/StyledComponents";
import { formatDateAndTime, formatDate } from "../utils/dates";
import { formatCurrency } from "../utils/numbers";
import TableContainer from "../components/shared/TableContainer";
import { checkPermission } from "../utils/permissions";

const Issues = () => {
    // ----- Context/Hooks
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { loggedUser } = useAppContext();

    // ----- State
    let [movementId, setMovementId] = useState(null);
    let [showDetailsModal, setShowDetailsModal] = useState(false);
    let [showReturnModal, setShowReturnModal] = useState(false);
    let [showEditReturnModal, setShowEditReturnModal] = useState(false);
    let [showMovementCreated, setShowMovementCreated] = useState(false);
    let [showMovementEdited, setShowMovementEdited] = useState(false);
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Hooks
    useEffect(() => {
        if (searchParams.get('created')) {
            setShowMovementCreated(true);
        }
    }, [searchParams]);


    // ----- Actions
    const handleShowDetails = (id) => {
        setMovementId(id);
        setShowDetailsModal(true);
    }

    const handleCloseDetailsModal = () => {
        setMovementId(null);
        setShowDetailsModal(false);
    }

    const handleShowReturnModal = (id) => {
        setMovementId(id);
        setShowReturnModal(true);
    }

    const handleShowEditReturnModal = (id) => {
        setMovementId(id);
        setShowEditReturnModal(true);
    }

    const handleCloseReturnModal = () => {
        setMovementId(null);
        setShowReturnModal(false);
    }

    const handleCloseEditReturnModal = () => {
        setMovementId(null);
        setShowEditReturnModal(false);
    }

    const handleReturn = () => {
        setShowMovementCreated(false);
        setShowReturnModal(false);
        setUpdateTable(!updateTable);
    }

    const handleEditReturn = () => {
        setShowMovementEdited(true);
        setShowEditReturnModal(false);
        setUpdateTable(!updateTable);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            <TableActionButton className="btn btn-primary" onClick={() => handleShowDetails(row.id)}>
                <FaMagnifyingGlass />
                Detalles
            </TableActionButton>
            {
                row.type === 'Salida' &&
                <TableActionButton className="btn btn-danger" disabled={row.returnMovement !== null} onClick={() => handleShowReturnModal(row.id)}>
                    <GrRevert />
                    Devolver
                </TableActionButton>
            }
            {
                row.type === 'Devolución' &&
                <TableActionButton className="btn btn-primary" onClick={() => handleShowEditReturnModal(row.id)}>
                    <MdModeEdit />
                    Editar
                </TableActionButton>
            }
        </div>
    );

    const columns = [
        {
            name: 'Fecha de registro',
            selector: row => formatDateAndTime(row.createdDate),
            sortable: true,
            sortField: 'createdDate',
        },
        {
            name: 'Tipo',
            selector: row => row.type,
            sortable: true,
            sortField: 'type',
        },
        {
            name: 'Fecha de despacho',
            selector: row => row.eventDate ? formatDate(row.eventDate) : '-',
            sortable: true,
            sortField: 'eventDate',
        },
        {
            name: 'Valor total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
        },
        {
            name: 'Acciones',
            width: "220px",
            cell: row => <Actions row={row} />,
        },
    ];

    const filters = [
        {
            column: 'type',
            input: 'select',
            label: 'Tipo',
            options: [
                { value: 'Salida', text: 'Salida' },
                { value: 'Devolución', text: 'Devolución' }
            ],
            operation: 'equal'
        },
        {
            column: 'createdDate',
            input: 'dates',
            label: 'Fecha de registro',
            operation: 'between',
        },
        {
            column: 'totalValue',
            input: 'range',
            label: 'Valor total',
            operation: 'between',
            length: 15
        }
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="">Despacho</h1>

            {showMovementCreated &&
                <div className="alert alert-success text-center mt-3" role="alert">
                    Despacho creado satisfactoriamente.
                </div>
            }

            {showMovementEdited &&
                <div className="alert alert-success text-center mt-3" role="alert">
                    Devolución editada satisfactoriamente.
                </div>
            }

            {checkPermission(loggedUser.permissions, 'manage_stock_issue') &&
                <div className="d-flex my-3 justify-content-end">
                    <AddButton className="btn btn-primary" onClick={() => navigate("/crear-despacho")}>
                        <FaPlus /> Nuevo despacho
                    </AddButton>
                </div>
            }

            <TableContainer
                columns={columns}
                fetchDataHandler={getIssueMovements}
                updateTable={updateTable}
                filters={filters}
            />

            {showDetailsModal &&
                <StockMovementDetailsModal
                    movementId={movementId}
                    closeHandler={handleCloseDetailsModal}
                    show={showDetailsModal}
                />
            }

            {showReturnModal &&
                <MovementReturnModal
                    movementId={movementId}
                    closeHandler={handleCloseReturnModal}
                    actionHandler={handleReturn}
                    show={showReturnModal}
                />
            }

            {showEditReturnModal &&
                <EditMovementReturnModal
                    returnId={movementId}
                    closeHandler={handleCloseEditReturnModal}
                    actionHandler={handleEditReturn}
                    show={showEditReturnModal}
                />
            }
        </div>
    )
}

export default Issues;