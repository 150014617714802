import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { getUser, editUser } from "../services/UsersService"
import { listRoles } from "../services/RolesService"
import { useAppContext } from "../contextApi/context"
import { USER_USERNAME_DUPLICATED, USER_EMAIL_DUPLICATED } from "../constants/apiMessages"

const EditUser = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { id } = useParams();

    // ----- State
    const [roles, setRoles] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [showUserEdited, setShowUserEdited] = useState(false);
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false)
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('')

    // ----- API Fetch Actions
    const fetchUserData = useCallback(async () => {
        return await getUser(loggedUser.token, id);
    }, [loggedUser.token, id]);

    const fetchRolesData = useCallback(async () => {
        return await listRoles(loggedUser.token);
    }, [loggedUser.token]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            username: '',
            roleId: '',
        }
    });

    useEffect(() => {
        fetchUserData().then(data => {
            setCurrentUser(data);
            reset(data);
        });

        fetchRolesData().then(data => {
            setRoles(data);
        });

    }, [fetchRolesData, fetchUserData, reset]);

    // ----- Actions
    const handleEdit = async (data) => {
        clearMessages();
        const response = await editUser(loggedUser.token, data);

        if (response.status === 200) {
            setShowUserEdited(true);
        } else {
            // Error from API
            const { data } = response;
            let errorMessageDuplicated = "El usuario contiene datos que ya se encuentran registrados:";

            if (data.includes(USER_USERNAME_DUPLICATED)) {
                errorMessageDuplicated += " Nombre de usuario,"
            }

            if (data.includes(USER_EMAIL_DUPLICATED)) {
                errorMessageDuplicated += " Correo electrónico,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);
        }
    }

    const clearMessages = () => {
        setShowUserEdited(false);
        setShowErrorDuplicatedData(false);
        setErrorDuplicatedData('');
    }

    // ----- Render
    return (
        <div className="container mt-4">
            {showUserEdited &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Usuario editado satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }
            <div className="mb-3">
                <Link to="/usuarios">Volver a página de usuarios</Link>
            </div>

            <h1>Editar Usuario</h1>

            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="firstName">Nombre</label>
                    <input className="form-control" type="text" id="firstName" autoComplete="off" {...register("firstName", { required: true, maxLength: 50 })} maxLength={50} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.firstName?.type === "required" && `Campo requerido`}
                        {errors.firstName?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="lastName">Apellido</label>
                    <input className="form-control" type="text" id="lastName" autoComplete="off" {...register("lastName", { required: true, maxLength: 50 })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.lastName?.type === "required" && `Campo requerido`}
                        {errors.lastName?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="email">Correo electrónico</label>
                    <input className="form-control" type="text" id="email" autoComplete="off" {...register("email", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.email?.type === "required" && `Campo requerido`}
                        {errors.email?.type === "maxLength" && `Formato inválido`}
                        {errors.email?.type === "pattern" && `Formato de correo incorrecto`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="username">Nombre de usuario</label>
                    <input className="form-control" type="text" id="username" autoComplete="off" {...register("username", { required: true, maxLength: 50 })} maxLength={50} />
                    <span className="my-1" role="alert">
                        {errors.username?.type === "required" && `Campo requerido`}
                        {errors.username?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="role">Rol</label>
                    <select className="form-select" id="role" {...register("roleId", { required: true })}>
                        <option value="">Seleccione un rol</option>
                        {roles?.map(role =>
                            (<option selected={role.id === currentUser?.roleId} key={role.id} value={role.id}>{role.name}</option>)
                        )}
                    </select>
                    <span className="my-1" role="alert">
                        {errors.roleId && `Campo requerido`}
                    </span>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar cambios</button>
                    </div>
                </form>
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin: 25px auto;
    padding: 40px;
    width: 80%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

export default EditUser;