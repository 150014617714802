import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getReceiptMovements = async (token, page, size, sortField, filtersData) => {
    sortField = sortField ? sortField : 'createdDate,desc';
    const url = `${BASE_API_URL}/stock-movements/receipt/search?page=${page}&size=${size}&sort=${sortField}`

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getReceiptMovements call: ', error);
            return error;
        });
}

export const getIssueMovements = async (token, page, size, sortField, filtersData) => {
    sortField = sortField ? sortField : 'createdDate,desc';
    const url = `${BASE_API_URL}/stock-movements/issue/search?page=${page}&size=${size}&sort=${sortField}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getIssueMovements call: ', error);
            return error;
        });
}

export const getMovementDetails = async (token, id) => {
    const url = `${BASE_API_URL}/stock-movements/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getMovementDetails call: ', error);
        return error;
    })
}

export const createMovement = async (token, data) => {
    const url = `${BASE_API_URL}/stock-movements`;

    const payload = {
        type: data.type,
        invoice: data.invoice,
        details: data.details,
        vehicleId: data.vehicleId,
        odometer: data.odometer,
        receivingUserId: data.receivingUserId,
        acceptAdjustmentOverPurchase: data.acceptAdjustmentOverPurchase,
        reversedMovement: data.reversedMovement,
        tax: data.tax,
        currency: data.currency,
        description: data.description,
        eventDate: data.eventDate
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createMovement call: ', error);
        return error.response;
    });
}

export const editReturnMovement = async (token, returnId, data) => {
    const url = `${BASE_API_URL}/stock-movements/return/${returnId}`;

    return axios.put(url, data, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        console.log('reponse', response);
        return response;
    }).catch(error => {
        console.log('Error on editReturnMovement call: ', error);
        return error.response;
    });
}

export const getTypes = async (token) => {
    const url = `${BASE_API_URL}/stock-movements/types`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getTypes call: ', error);
            return error;
        });
}

