import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getTags = async (token, page, size, sortField, filtersData) => {

    const url = sortField ? `${BASE_API_URL}/tags/search?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/tags/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getTags call: ', error);
            return error;
        });
}


export const searchTagsByName = async (token, text) => {
    const url = `${BASE_API_URL}/tags/list`;

    return axios.post(url, text, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on searchTagsByName call: ', error);
            return error;
        });
}

export const getTag = async (token, id) => {
    const url = `${BASE_API_URL}/tags/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getTag call: ', error);
        return error;
    })
}

export const createTag = async (token, data) => {
    const url = `${BASE_API_URL}/tags`;
    const payload = {
        name: data.name,
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createTag call: ', error);
        return error.response;
    });
}

export const editTag = async (token, tagId, data) => {
    const url = `${BASE_API_URL}/tags/${tagId}`;
    const payload = {
        name: data.name,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editTag call: ', error);
        return error.response;
    });
}

export const deleteTag = async (token, tagId) => {
    const url = `${BASE_API_URL}/tags/${tagId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteTag call: ', error);
        return error.response;
    });
}
