const Spinner = () => {

    // ----- Render
    return (
        <div className="d-flex justify-content-center my-5 text-secondary">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando...</span>
            </div>
        </div>
    );
}

export default Spinner;
