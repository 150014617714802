import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getRoles = async (token, page, size, sortField, filtersData) => {

    const url = sortField ? `${BASE_API_URL}/roles/search?page=${page}&size=${size}&sort=${sortField}`
                          : `${BASE_API_URL}/roles/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getRoles call: ', error);
            return error;
        });
}

export const listRoles = async (token) => {

    const url = `${BASE_API_URL}/roles`

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on listRoles call: ', error);
            return error;
        });
}

export const getRole = async (token, id) => {
    const url = `${BASE_API_URL}/roles/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getRole call: ', error);
        return error;
    })
}

export const createRole = async (token, data) => {
    const url = `${BASE_API_URL}/roles`;
    const payload = {
        name: data.name,
        description: data.description,
        permissions: []
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createRole call: ', error);
        return error.response;
    });
}

export const editRole = async (token, roleId, data) => {
    const url = `${BASE_API_URL}/roles/${roleId}`;
    const payload = {
        name: data.name,
        description: data.description
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editRole call: ', error);
        return error.response;
    });
}

export const deleteRole = async (token, roleId) => {
    const url = `${BASE_API_URL}/roles/${roleId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteRole call: ', error);
        return error.response;
    });
}

export const editRolePermissions = async (token, id, data) => {
    const url = `${BASE_API_URL}/roles/${id}/permissions`;
    const payload = {
        permissions: data,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on editRolePermissions call: ', error);
        return error;
    });
}