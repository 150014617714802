import { useEffect, useState } from "react";

import { styled } from "styled-components";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";

import { useAppContext } from "../contextApi/context";
import { assignArticleProvider, deleteAssignment, editAssignment } from "../services/ArticleProviderService";
import { searchArticlesByName } from "../services/ArticlesService";
import DeleteConfirmationModal from "../components/shared/DeleteConfirmationModal";
import { TableActionButton } from "../styles/StyledComponents";
import EditAssignmentModal from "../components/shared/EditAssignmentModal";
import { PROVIDER_ARTICLE_BRAND_DUPLICATED, PROVIDER_ARTICLE_INTERNAL_CODE_DUPLICATED, PROVIDER_ARTICLE_WITH_STOCK } from "../constants/apiMessages";

const AssignArticle = ({ providerId, articles, updateHandler }) => {

    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // ----- State
    let [assignments, setAssignments] = useState(articles)
    let [availableArticles, setAvailableArticles] = useState([]);
    const [selectedArticleId, setSelectedArticleId] = useState("");
    const [showArticleError, setShowArticleError] = useState(false);
    const [showAssignmentCreated, setShowAssignmentCreated] = useState(false)
    const [timer, setTimer] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showAssignmentDeleted, setShowAssignmentDeleted] = useState(false);
    const [showErrorAssignedInStock, setShowErrorAssignedInStock] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [showEditAssignment, setShowEditAssignment] = useState(false);
    const [showAssignmentEdited, setShowAssignmentEdited] = useState(false);
    const [assignmentToEdit, setAssignmentToEdit] = useState(null);
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('');
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false);

    useEffect(() => {
        setAssignments(articles);
    }, [articles]);

    // ----- Actions
    const handleAssign = async (data) => {
        clearMessages();

        if (selectedArticleId !== "") {
            const payload = {
                providerId,
                articleId: selectedArticleId,
                internalProviderCode: data.internalProviderCode,
                brand: data.brand,
            };

            let response = await assignArticleProvider(loggedUser.token, payload);
            if (response.status === 201) {
                reset();
                updateHandler();
                setShowAssignmentCreated(true);
            }
            else {
                const { data } = response;
                let errorMessageDuplicated = "La asociación no se puede crear porque hay datos ya registrados:";

                if (data.includes(PROVIDER_ARTICLE_INTERNAL_CODE_DUPLICATED)) {
                    errorMessageDuplicated += " Código interno,"
                }

                if (data.includes(PROVIDER_ARTICLE_BRAND_DUPLICATED)) {
                    errorMessageDuplicated += " Marca,"
                }

                setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
                setShowErrorDuplicatedData(true);
            }

        } else {
            setShowArticleError(true);
        }
    }

    const handleFormErrors = () => {
        if (selectedArticleId === "") {
            setShowArticleError(true);
        }
    }

    const handleChange = (event, newValue) => {
        if (newValue != null) {
            setSelectedArticleId(newValue.id)
        }
    }

    const handleSelectOption = (e, newValue, reason) => {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
            if (reason === 'clear') {
                setAvailableArticles([]);
                setSelectedArticleId("");
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    clearMessages();
                    const response = await searchArticlesByName(loggedUser.token, newValue)
                    setAvailableArticles(response?.map(tag => ({ id: tag.id, label: tag.name })));
                }
            }
            else {
                setAvailableArticles([]);
                setSelectedArticleId("");
            }
        }, 500);

        setTimer(newTimer)
    }

    const openDeleteConfirmation = (assignmentId) => {
        setIdToDelete(assignmentId);
        setShowDeleteConfirmation(true);
    }

    const handleCloseModal = () => {
        setIdToDelete(null);
        setShowDeleteConfirmation(false);
    }

    const handleDelete = async () => {
        clearMessages();
        const response = await deleteAssignment(loggedUser.token, idToDelete);
        if (response.status === 204) {
            setShowDeleteConfirmation(false);
            let deletedAssignment = assignments.find(assignment => assignment.id === idToDelete);
            assignments = assignments.filter(function (assignment) {
                return assignment !== deletedAssignment
            });
            setAssignments(assignments);
            setShowAssignmentDeleted(true);
        } else {
            // Error from API
            const { data } = response;

            if (data === PROVIDER_ARTICLE_WITH_STOCK) {
                setShowDeleteConfirmation(false);
                setShowErrorAssignedInStock(true);
            }
        }
    }

    const openEditModal = (assignmentId) => {
        let assignmentFound = assignments.find(article => article.id === assignmentId);
        setAssignmentToEdit(assignmentFound);
        setShowEditAssignment(true);
    }

    const handleCloseEditModal = () => {
        setAssignmentToEdit(null);
        setShowEditAssignment(false);
    }

    const handleEdit = async (data) => {
        clearMessages();

        const response = await editAssignment(loggedUser.token, data);
        if (response.status === 200) {
            setShowEditAssignment(false);

            let { id, brand, internalProviderCode } = response.data;
            let editedAssignment = assignments.find(assignment => assignment.id === id);
            editedAssignment.brand = brand;
            editedAssignment.internalProviderCode = internalProviderCode;

            setAssignments(assignments);
            setShowAssignmentEdited(true);
        } else {
            // Error from API
        }
    }

    const clearMessages = () => {
        setShowArticleError(false);
        setShowAssignmentCreated(false);
        setShowAssignmentEdited(false);
        setShowAssignmentDeleted(false);
        setShowErrorAssignedInStock(false);
        setShowErrorDuplicatedData(false);
    }

    // ----- Render
    return (
        <ContainerStyled>
            <h1>Artículos</h1>

            {showAssignmentCreated &&
                <div className="alert alert-success text-center" role="alert">
                    Artículo asociado al proveedor satisfactoriamente.
                </div>
            }

            {showErrorAssignedInStock &&
                <div className="alert alert-danger text-center" role="alert">
                    El artículo asociado no se puede eliminar porque ya es parte del inventario.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            <form onSubmit={handleSubmit(handleAssign, handleFormErrors)}>
                <label className="fw-bold">Artículo</label>
                <Autocomplete
                    disablePortal
                    id="size-small-outlined"
                    size="small"
                    options={availableArticles}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Buscar" />}
                    onChange={handleChange}
                    onInputChange={handleSelectOption}
                    noOptionsText={"Buscar por nombre"}
                    className="mt-1 w-100"
                />
                <span className="my-1" role="alert">
                    {showArticleError && `Campo requerido`}
                </span>

                <label className="d-flex mb-1" htmlFor="brand">Marca</label>
                <input className="form-control" type="text" id="brand" autoComplete="off" maxLength={25} {...register("brand", { required: true, maxLength: 25 })} />
                <span className="my-1" role="alert">
                    {errors.brand?.type === "required" && `Campo requerido`}
                    {errors.brand?.type === "maxLength" && `Formato inválido`}
                </span>

                <label className="d-flex mb-1" htmlFor="internalProviderCode">Código interno</label>
                <input className="form-control" type="text" id="internalProviderCode" autoComplete="off" maxLength={25} {...register("internalProviderCode", { required: true, maxLength: 25 })} />
                <span className="my-1" role="alert">
                    {errors.internalProviderCode?.type === "required" && `Campo requerido`}
                    {errors.internalProviderCode?.type === "maxLength" && `Formato inválido`}
                </span>

                <button className="btn btn-danger mt-3" type="submit">Asociar artículo</button>
            </form>

            {showAssignmentDeleted && assignments.length > 0 &&
                <div className="alert alert-success text-center mt-4" role="alert">
                    Artículo eliminado satisfactoriamente.
                </div>
            }

            {showAssignmentEdited &&
                <div className="alert alert-success text-center mt-4" role="alert">
                    Artículo editado satisfactoriamente.
                </div>
            }

            {assignments?.length > 0 &&
                <div className="d-flex justify-content-center">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Artículo</th>
                                <th scope="col">Código interno</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assignments?.map(assignment =>
                            (
                                <tr key={assignment.id}>
                                    <td>{assignment.articleName}</td>
                                    <td>{assignment.internalProviderCode}</td>
                                    <td>{assignment.brand}</td>
                                    <td className="d-flex">
                                        <TableActionButton className="btn btn-primary" onClick={() => openEditModal(assignment.id)}>
                                            <MdModeEdit /> Editar
                                        </TableActionButton>
                                        <TableActionButton className="btn btn-danger" onClick={() => openDeleteConfirmation(assignment.id)}>
                                            <MdDelete />
                                            Eliminar
                                        </TableActionButton>
                                    </td>
                                </tr>
                            )
                            )}
                        </tbody>
                    </table>
                </div>
            }

            {showDeleteConfirmation &&
                <DeleteConfirmationModal
                    text={"Desea eliminar el artículo del proveedor?"}
                    closeHandler={handleCloseModal}
                    actionHandler={handleDelete}
                    show={showDeleteConfirmation}
                />
            }

            {showEditAssignment &&
                <EditAssignmentModal
                    title={"Editar artículo"}
                    assignment={assignmentToEdit}
                    closeHandler={handleCloseEditModal}
                    actionHandler={handleEdit}
                    show={showEditAssignment}
                />
            }
        </ContainerStyled >
    );
}

// ----- Styles
const ContainerStyled = styled.div`
    
    form {
        border: #dee2e6 solid 1px;
        display: grid;
        margin: 30px auto 40px;
        padding: 30px;
        width: 50%;
        
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        > button {
            background-color: #D04437;
        }  
    }
`;

export default AssignArticle;
