export const permissionsHierarchy = [
    { code: 'view_financial_report', to: '/reporte-financiero' },
    { code: 'view_movements_report', to: '/reporte-ajustes' },
    { code: 'view_stock_report', to: '/reporte-existencias' },
    { code: 'view_minimums_report', to: '/reporte-minimos' },
    { code: 'view_stock', to: '/inventario' },
    { code: 'view_stock_receipt', to: '/compras' },
    { code: 'view_stock_issue', to: '/despacho' },
    { code: 'view_articles', to: '/articulos' },
    { code: 'view_providers', to: '/proveedores' },
    { code: 'view_vehicles', to: '/vehiculos' },
    { code: 'view_tags', to: '/etiquetas' },
    { code: 'view_users', to: '/usuarios' },
    { code: 'view_roles', to: '/roles' },
    { code: 'manage_exchange', to: '/tipo-cambio' },
];