import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";

import { formatDate } from '../../utils/dates';
import { formatCurrency, getSubTotal, getTotalDiscount, getTotalExtraCharges, getTotalItems, getTaxIncludedTotal } from "../../utils/numbers";

const ReceiptMovementConfirmationModal = ({ actionHandler, closeHandler, details, tax, show, currencySymbol, data, provider }) => {
    // ----- Actions
    const handleClose = () => {
        closeHandler();
    }

    const handleAction = () => {
        actionHandler();
    }

    // ----- Render
    return (
        <Modal show={show} size="lg" backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                Detalles de movimiento
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <label className="fw-bold me-3">Proveedor:</label>
                    <p>{provider.name}</p>
                </div>

                <div className="d-flex">
                    <label className="fw-bold me-3">Factura:</label>
                    <p>{data.invoice}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-3">Fecha de factura:</label>
                    <p>{formatDate(data.eventDate)}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-3">Impuesto aplicado (IVA%):</label>
                    <p>{`${data.tax}%`}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-3">Moneda:</label>
                    <p>{data.currency === 'CRC' ? 'Colón (₡)' : 'Dólar ($)'}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-3">Descripción:</label>
                    <p>{data.description.length > 0 ? data.description : "--"}</p>
                </div>

                {details && <>
                    <div className="d-flex flex-column">
                        <table className="table table-bordered my-2">
                            <thead>
                                <tr>
                                    <th scope="col">Artículo</th>
                                    <th scope="col">Marca</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Valor por unidad</th>
                                    <th scope="col">Descuento</th>
                                    <th scope="col">Otros cargos</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.map((item, index) =>
                                (
                                    <tr key={index}>
                                        <td>{item.articleName}</td>
                                        <td>{item.brand}</td>
                                        <td>{item.amount}</td>
                                        <td>{`${currencySymbol}${formatCurrency(item.itemsValue)}`}</td>
                                        <td>{`${currencySymbol}${formatCurrency(item.discount)}`}</td>
                                        <td>{`${currencySymbol}${formatCurrency(item.extraCharges)}`}</td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-start">
                        <div className="me-5 text-center">
                            <label className="fw-bold">Subtotal</label>
                            <p className="mb-1">{`${currencySymbol}${formatCurrency(getTotalItems(details))}`}</p>
                        </div>
                        <div className="me-5 text-center">
                            <label className="fw-bold">Descuentos</label>
                            <p className="mb-1">{`${currencySymbol}${formatCurrency(getTotalDiscount(details))}`}</p>
                        </div>
                        <div className="me-5 text-center">
                            <label className="fw-bold">Otros cargos</label>
                            <p className="mb-1">{`${currencySymbol}${formatCurrency(getTotalExtraCharges(details))}`}</p>
                        </div>
                        <div className="me-5 text-center">
                            <label className="fw-bold">Total sin impuestos</label>
                            <p className="mb-1">{`${currencySymbol}${formatCurrency(getSubTotal(details))}`}</p>
                        </div>
                        <div className="me-5 text-center">
                            <label className="fw-bold">Total</label>
                            <p className="mb-1">{`${currencySymbol}${formatCurrency(getTaxIncludedTotal(details, tax))}`}</p>
                        </div>
                    </div>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <p className='fw-bold me-3'>Desea guardar el movimiento?</p>
                <ModalButton onClick={handleClose}>
                    Cerrar
                </ModalButton>
                <Button onClick={handleAction}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const ModalButton = styled(Button)`
    background-color: #A5A7A0;
    border-color: #A5A7A0;
    
    &:hover {
        background-color: #A5A7A0;
        border-color: #A5A7A0;
    }
`;

export default ReceiptMovementConfirmationModal;
