import { useNavigate, Link } from "react-router-dom"
import { styled } from "styled-components"

import { TbLogout2 } from "react-icons/tb";

import { useAppContext } from '../../contextApi/context'
import logo from "../../assets/img/logo-192x192.png"

const Header = () => {
    // ----- Context/Hooks
    const { loggedUser, setLoggedUser, setLoggedInTime, setLastActionTime } = useAppContext();
    const navigate = useNavigate();

    // ----- Actions
    const logout = () => {
        localStorage.removeItem("loggedUser");
        setLoggedUser(null);
        localStorage.removeItem("loggedInTime");
        setLoggedInTime(null);
        localStorage.removeItem("lastActionTime");
        setLastActionTime(null);
        localStorage.removeItem("logoutModalSeconds");
        navigate("/login");
    }

    const home = () => {
        navigate("/dashboard");
    }

    // ----- Render
    return (
        <HeaderStyled>
            {loggedUser &&
                <>
                    <Logo onClick={home} src={logo}></Logo>
                    <div className="d-flex">
                        <Link className="me-3" to='/perfil-usuario'>
                            {loggedUser.firstName} {loggedUser.lastName}
                        </Link>
                        <button onClick={logout} className="sidebar-link">
                            <TbLogout2 />
                            <span>Salir</span>
                        </button>
                    </div>
                </>
            }

        </HeaderStyled>
    );
}

// ----- Styles
const HeaderStyled = styled.header`
    align-items: center;
    background-color: #D04437;
    color: #FFF;
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding-right: 20px;

    button {
        align-items: center;
        background: none;
        border: none;
        border-left: 1px solid #FFFFFF;
        color: #FFFFFF;
        display: flex;

        > svg {
            margin-right: 5px;
        }
    }

    a {
        color: #FFF;
    }
`;

const Logo = styled.img`
    background-color: #FFFFFF;
    cursor: pointer;
    height: 100%;
    width: 70px;
`;

export default Header;
