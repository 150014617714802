import { useState } from 'react';
import { FaMagnifyingGlass } from "react-icons/fa6";

import { getAdjustmentsHistory, getAdjustmentMovements } from "../services/ReportsService";
import TableContainer from "../components/shared/TableContainer";
import { formatCurrency } from "../utils/numbers";
import { formatDateAndTime } from '../utils/dates';
import { AddButton, TableActionButton } from "../styles/StyledComponents";
import StockMovementDetailsModal from '../components/shared/StockMovementDetailsModal';


const ReportStockAdjustments = () => {
    // ----- State
    let [showAdjustmentsTable, setShowAdjustmentsTable] = useState(false);
    let [currentArticle, setCurrentArticle] = useState({});
    const [updateTable, setUpdateTable] = useState(false);
    let [movementId, setMovementId] = useState(null);
    let [showDetailsModal, setShowDetailsModal] = useState(false);

    // ----- Actions
    const handleShowAdjustmentsTable = (articleId, articleName, brand) => {
        setShowAdjustmentsTable(false);
        
        setTimeout(() => {    
            setCurrentArticle({ articleId, name: articleName, brand });
            setUpdateTable(!updateTable);
            setShowAdjustmentsTable(true);
        }, 100);

    }

    const handleShowDetails = (id) => {
        setMovementId(id);
        setShowDetailsModal(true);
    }

    const handleCloseDetailsModal = () => {
        setMovementId(null);
        setShowDetailsModal(false);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            <TableActionButton className="btn btn-primary" onClick={() => handleShowAdjustmentsTable(row.articleId, row.articleName, row.brand)}>
                <FaMagnifyingGlass />
                Ver Ajustes
            </TableActionButton>
        </div>
    );

    const columns = [
        {
            name: 'Artículo',
            selector: row => row.articleName,
            sortable: true,
            sortField: 'articleName',
        },
        {
            name: 'Marca',
            selector: row => row.brand,
            sortable: true,
            sortField: 'brand',
        },
        {
            name: 'Ajustes Positivos',
            selector: row => row.positiveAdjustments,
            sortable: true,
            sortField: 'positiveAdjustments',
        },
        {
            name: 'Ajustes Negativos',
            selector: row => row.negativeAdjustments,
            sortable: true,
            sortField: 'negativeAdjustments',
        },
        {
            name: 'Acciones',
            width: "150px",
            cell: row => <Actions row={row} />,
        }
    ];

    const filters = [
        {
            column: 'articleName',
            input: 'text',
            label: 'Artículo',
            operation: 'like',
            length: 50
        },
        {
            column: 'brand',
            input: 'text',
            label: 'Marca',
            operation: 'like',
            length: 25
        },
    ];

    const AdjustmentsTableActions = ({ row }) => (
        <div className="d-flex">
            <TableActionButton className="btn btn-primary" onClick={() => handleShowDetails(row.id)}>
                <FaMagnifyingGlass />
                Detalles
            </TableActionButton>
        </div>
    );

    const AdjustmentsTableColumns = [
        {
            name: 'Tipo',
            selector: row => row.type,
        },
        {
            name: 'Fecha',
            id: 'createdDate',
            selector: row => formatDateAndTime(row.createdDate),
            sortable: true,
            sortField: 'createdDate',
        },
        {
            name: 'Valor total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
            sortable: true,
            sortField: 'totalValue',
        },
        {
            name: 'Acciones',
            width: "150px",
            cell: row => <AdjustmentsTableActions row={row} />,
        }
    ];

    const AdjustmentsTableFilters = [
        {
            column: 'type',
            input: 'select',
            label: 'Tipo',
            options: [
                { value: 'Ajuste positivo', text: 'Ajuste positivo' },
                { value: 'Ajuste negativo', text: 'Ajuste negativo' }
            ],
            operation: 'equal'
        },
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="mb-5">Reporte de Ajustes de Inventario</h1>

            <TableContainer
                columns={columns}
                fetchDataHandler={getAdjustmentsHistory}
                updateTable={true}
                filters={filters}
                afterFilteringHandler={() => setShowAdjustmentsTable(false)}
            />

            {showAdjustmentsTable &&
                <div className='my-5'>
                    <h3>Ajustes para: {currentArticle.name} ({currentArticle.brand})</h3>

                    <div className="d-flex justify-content-end">
                        <AddButton className="btn btn-primary" onClick={() => setShowAdjustmentsTable(false)}>
                            Ocultar detalles
                        </AddButton>
                    </div>

                    <TableContainer
                        columns={AdjustmentsTableColumns}
                        fetchDataHandler={getAdjustmentMovements}
                        updateTable={updateTable}
                        filters={AdjustmentsTableFilters}
                        pathVariables={currentArticle}
                        defaultSort={'createdDate'}
                    />
                </div>
            }

            {showDetailsModal &&
                <StockMovementDetailsModal
                    movementId={movementId}
                    closeHandler={handleCloseDetailsModal}
                    show={showDetailsModal}
                />
            }
        </div>
    )
}

export default ReportStockAdjustments;