import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { getTag, editTag } from "../services/TagsService"
import { useAppContext } from "../contextApi/context"
import { TAG_NAME_DUPLICATED } from "../constants/apiMessages"

const EditTag = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { id } = useParams();

    // ----- State
    const [currentTag, setCurrentTag] = useState(null);
    const [showTagEdited, setShowTagEdited] = useState(false);
    const [showErrorDuplicatedTag, setShowErrorDuplicatedTag] = useState(false);

    // ----- API Fetch Actions
    const fetchTagData = useCallback(async () => {
        return await getTag(loggedUser.token, id);
    }, [loggedUser.token, id]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
        }
    });

    useEffect(() => {
        fetchTagData().then(data => {
            setCurrentTag(data);
            const { name } = data;
            reset({ name });
        });
    }, [fetchTagData, reset]);

    // ----- Actions
    const handleEdit = async (data) => {
        clearMessages();
        const response = await editTag(loggedUser.token, currentTag.id, data);

        if (response.status === 200) {
            setShowTagEdited(true);
        } else {
            // Error from API
            const { data } = response;

            if (data.includes(TAG_NAME_DUPLICATED)) {
                setShowErrorDuplicatedTag(true);
            }
        }
    }

    const clearMessages = () => {
        setShowTagEdited(false);
        setShowErrorDuplicatedTag(false);
    }

    // ----- Render
    return (
        <div className="container mt-4">
            {showTagEdited &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Etiqueta editada satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedTag &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    El nombre de la etiqueta ya se encuentra registrado.
                </div>
            }

            <div className="mb-3">
                <Link to="/etiquetas">Volver a página de etiquetas</Link>
            </div>

            <h1>Editar Etiqueta</h1>
            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                    <input className="form-control" type="text" id="name" autoComplete="off" maxLength={50} {...register("name", { required: true, maxLength: 50 })} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.name?.type === "required" && `Campo requerido`}
                        {errors.name?.type === "maxLength" && `Formato inválido`}
                    </span>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar cambios</button>
                    </div>
                </form>
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin: 25px auto 0;
    padding: 40px 40px 20px;
    width: 70%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

export default EditTag;