import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";

import { useAppContext } from '../contextApi/context'
import { getReceiptMovements } from "../services/StockMovementService";
import { exportReceipts } from "../services/ExportService";
import StockMovementDetailsModal from "../components/shared/StockMovementDetailsModal";
import MovementReturnModal from "../components/MovementReturnModal";
import { TableActionButton, AddButton } from "../styles/StyledComponents";
import { formatDateAndTime, formatDate } from "../utils/dates";
import { formatCurrency } from "../utils/numbers";
import { checkPermission } from "../utils/permissions";
import TableContainer from "../components/shared/TableContainer";

const Receipts = () => {
    // ----- Context/Hooks
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { loggedUser } = useAppContext();

    // ----- State
    let [movementId, setMovementId] = useState(null);
    let [showDetailsModal, setShowDetailsModal] = useState(false);
    let [showReturnModal, setShowReturnModal] = useState(false);
    let [showMovementCreated, setShowMovementCreated] = useState(false);
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Hooks
    useEffect(() => {
        if (searchParams.get('created')) {
            setShowMovementCreated(true)
        }
    }, [searchParams]);

    // ----- Actions
    const handleShowDetails = (id) => {
        setMovementId(id);
        setShowDetailsModal(true);
    }

    const handleCloseDetailsModal = () => {
        setMovementId(null);
        setShowDetailsModal(false);
    }

    const handleCloseReturnModal = () => {
        setMovementId(null);
        setShowReturnModal(false);
    }

    const handleReturn = () => {
        setShowReturnModal(false);
        setUpdateTable(!updateTable);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            <TableActionButton className="btn btn-primary" onClick={() => handleShowDetails(row.id)}>
                <FaMagnifyingGlass />
                Detalles
            </TableActionButton>
        </div>
    );

    const columns = [
        {
            name: 'Fecha de registro',
            selector: row => formatDateAndTime(row.createdDate),
            sortable: true,
            sortField: 'createdDate',
        },
        {
            name: 'Factura',
            selector: row => row.invoice,
            sortable: true,
            sortField: 'invoice',
        },
        {
            name: 'Fecha de factura',
            selector: row => row.eventDate ? formatDate(row.eventDate) : '-',
            sortable: true,
            sortField: 'eventDate',
        },
        {
            name: 'Valor total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
        },
        {
            name: 'Acciones',
            width: "210px",
            cell: row => <Actions row={row} />,
        },
    ];

    const filters = [
        {
            column: 'createdDate',
            input: 'dates',
            label: 'Fecha',
            operation: 'between',
        },
        {
            column: 'invoice',
            input: 'text',
            label: 'Factura',
            operation: 'like',
            length: 10
        },
        {
            column: 'totalValue',
            input: 'range',
            label: 'Valor total',
            operation: 'between',
            length: 15
        }
    ];

    const exportOptions = checkPermission(loggedUser.permissions, 'export_stock_receipt') ? {
        handler: exportReceipts
    } : null;

    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="">Compras</h1>

            {showMovementCreated &&
                <div className="alert alert-success text-center mt-3" role="alert">
                    Compra creada satisfactoriamente.
                </div>
            }

            {checkPermission(loggedUser.permissions, 'manage_stock_receipt') &&
                <div className="d-flex mt-3 justify-content-end">
                    <AddButton className="btn btn-primary" onClick={() => navigate("/crear-compra")}>
                        <FaPlus /> Nueva compra
                    </AddButton>
                </div>
            }

            <TableContainer
                columns={columns}
                fetchDataHandler={getReceiptMovements}
                updateTable={updateTable}
                filters={filters}
                exportOptions={exportOptions}
            />

            {showDetailsModal &&
                <StockMovementDetailsModal
                    movementId={movementId}
                    closeHandler={handleCloseDetailsModal}
                    show={showDetailsModal}
                />
            }

            {showReturnModal &&
                <MovementReturnModal
                    movementId={movementId}
                    closeHandler={handleCloseReturnModal}
                    actionHandler={handleReturn}
                    show={showReturnModal}
                />
            }
        </div>
    )
}

export default Receipts;