import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getArticles = async (token, page, size, sortField, filtersData) => {
    
    const url = sortField ? `${BASE_API_URL}/articles/search?page=${page}&size=${size}&sort=${sortField}`
                          : `${BASE_API_URL}/articles/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getArticles call: ', error);
            return error;
        });
}

export const getArticle = async (token, id) => {
    const url = `${BASE_API_URL}/articles/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getArticle call: ', error);
        return error;
    })
}

export const createArticle = async (token, data) => {
    const url = `${BASE_API_URL}/articles`;
    const payload = {
        name: data.name,
        internalCode: data.internalCode,
        minimumAmount: data.minimumAmount,
        description: data.description,
        measureUnit: data.measureUnit,
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createArticle call: ', error);
        return error.response;
    });
}

export const editArticle = async (token, articleId, data) => {
    const url = `${BASE_API_URL}/articles/${articleId}`;
    const payload = {
        name: data.name,
        internalCode: data.internalCode,
        minimumAmount: data.minimumAmount,
        description: data.description,
        measureUnit: data.measureUnit,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editArticle call: ', error);
        return error.response;
    });
}

export const deleteArticle = async (token, articleId) => {
    const url = `${BASE_API_URL}/articles/${articleId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteArticle call: ', error);
        return error.response;
    });
}

export const getUnits = async (token) => {
    const url = `${BASE_API_URL}/articles/units`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getUnits call: ', error);
            return error;
        });
}

export const addTag = async (token, articleId, tagName) => {
    const url = `${BASE_API_URL}/articles/${articleId}/tags`;
    const payload = {
        name: tagName
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on addTag call: ', error);
        return error.response;
    });
}

export const removeTag = async (token, articleId, tagId) => {
    const url = `${BASE_API_URL}/articles/${articleId}/tags/${tagId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on removeTag call: ', error);
        return error.response;
    });
}

export const searchArticlesByName = async (token, text) => {
    const url = `${BASE_API_URL}/articles/list`;

    return axios.post(url, text, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on searchTagsByName call: ', error);
            return error;
        });
}
