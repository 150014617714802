import { styled } from "styled-components"

import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

const NoPermission = () => {
    // ----- Render
    return (
        <NoPermissionStyled>
            <MdOutlineDoNotDisturbAlt />
            El usuario no cuenta con permisos para accesar a esta página
        </NoPermissionStyled>
    )
}

// ----- Styles
const NoPermissionStyled = styled.footer`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    margin-top: 80px;

    > svg {
        color: #D04437;
        font-size: 150px;
        margin-bottom: 30px;
    }
`;

export default NoPermission;