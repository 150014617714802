import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getCurrency = async (token) => {

    const url = `${BASE_API_URL}/exchange-references`

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on getCurrency call: ', error);
            return error;
        });
}

export const editCurrency = async (token, currencyId, data) => {
    const url = `${BASE_API_URL}/exchange-references/${currencyId}`;
    const payload = {
        referenceValue: data.referenceValue,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editCurrency call: ', error);
        return error.response;
    });
}
