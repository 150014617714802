import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import { styled } from "styled-components";

const StockAdjustmentModal = ({ type, show, article, closeHandler, actionHandler }) => {
    // ----- Context/Hooks
    const { register, handleSubmit, formState: { errors } } = useForm();

    // ----- State
    let [showAmountError, setShowAmountError] = useState(false);

    // ----- Actions
    const handleClose = () => {
        closeHandler();
    }

    const handleAction = (data) => {
        setShowAmountError(false);

        if (type === 'ADJUSTMENT_DOWN' && data.amount > article.amount) {
            setShowAmountError(true);
        }
        else {
            const payload = {
                type,
                description: data.description,
                details: [{
                    articleId: article.articleId,
                    amount: parseInt(data.amount),
                    brand: article.brand,
                }],
            };

            actionHandler(payload);
        }
    }

    // ----- Render
    return (
        <Modal show={show} backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                {type === 'ADJUSTMENT_UP' ? 'Ajuste positivo' : 'Ajuste negativo'}
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <label className="fw-bold me-2">Artículo:</label>
                    <p className='mb-2'>{article.articleName}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-2">Cantidad actual:</label>
                    <p className='mb-2'>{article.amount}</p>
                </div>

                <hr />

                <FormStyled onSubmit={handleSubmit(handleAction)}>
                    <label className="d-flex mb-1" htmlFor="amount">{type === 'ADJUSTMENT_UP' ? 'Cantidad a aumentar' : 'Cantidad a deducir'}</label>
                    <input className="form-control" type="text" id="amount" autoComplete="off" maxLength={10} {...register("amount", { required: true, maxLength: 10, pattern: /^([1-9][0-9]+|[1-9])$/ })} />
                    <span className="my-1" role="alert">
                        {errors.amount?.type === "required" && `Campo requerido`}
                        {errors.amount?.type === "maxLength" && `Formato inválido`}
                        {errors.amount?.type === "pattern" && `Sólo se permiten números mayores a 0`}
                        {showAmountError && `La cantidad a deducir debe ser igual o menor a la cantidad actual`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                    <textarea className="form-control" id="description" maxLength={100} rows="3" {...register("description", { maxLength: 100 })} />
                    <span className="my-1" role="alert">
                        {errors.description?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <button className="btn btn-danger mt-3" type="submit">Guardar</button>
                </FormStyled>
            </Modal.Body>
        </Modal>
    );
}

const FormStyled = styled.form`
    display: grid;

    > label {
        font-size: 14px;
        font-weight: bold;
    }

    > span {
        color: #D04437;
        font-size: 12px;
        min-height: 12px;
    }

    > button {
        background-color: #D04437;
    }
`;

export default StockAdjustmentModal;
