import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { getCurrency, editCurrency } from "../services/ConfigurationService"
import { useAppContext } from "../contextApi/context"

const ExchangeRate = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    const [currency, setCurrency] = useState(null);
    const [showCurrencyEdited, setShowCurrencyEdited] = useState(false);

    // ----- API Fetch Actions
    const fetchCurrencyData = useCallback(async () => {
        return await getCurrency(loggedUser.token);
    }, [loggedUser.token]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            referenceValue: '',
        }
    });

    useEffect(() => {
        fetchCurrencyData().then(data => {
            setCurrency(data[0]);
            const { referenceValue } = data[0];
            reset({ referenceValue });
        });
    }, [fetchCurrencyData, reset]);

    // ----- Actions
    const handleEdit = async (data) => {
        clearMessages();
        const response = await editCurrency(loggedUser.token, currency.id, data);

        if (response.status === 200) {
            setShowCurrencyEdited(true);
        } else {
            // Error from API
        }
    }

    const clearMessages = () => {
        setShowCurrencyEdited(false);
    }

    // ----- Render
    return (
        <div className="container mt-4">
            <h1>Editar tipo de cambio</h1>
            
            {showCurrencyEdited &&
                <div className="alert alert-success text-center mt-5 mb-0" role="alert">
                    Tipo de cambio actualizado satisfactoriamente.
                </div>
            }

            <FormContainer>
                <form onSubmit={handleSubmit(handleEdit)}>
                    <label className="d-flex mb-1" htmlFor="referenceValue">Valor en colones</label>
                    <input className="form-control" type="text" id="referenceValue" autoComplete="off" maxLength={6} {...register("referenceValue", { required: true, maxLength: 6, pattern: /^\d+(?:\.\d{1,2})?$/ })} autoFocus />
                    <span className="my-1" role="alert">
                        {errors.referenceValue?.type === "required" && `Campo requerido`}
                        {errors.referenceValue?.type === "maxLength" && `Formato inválido`}
                        {errors.referenceValue?.type === "pattern" && `Sólo se permiten números mayores a 0 (5 - 3.50)`}
                    </span>
                    
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger mt-3" type="submit">Guardar</button>
                    </div>
                </form>
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin: 20px auto 0;
    padding: 40px 40px 20px;
    width: 70%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        button {
            background-color: #D04437;
            width: 30%;
        }
    }
`;

export default ExchangeRate;