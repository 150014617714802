import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";
import { useForm } from "react-hook-form";

const ReceiptEditDetailModal = ({ show, detail, closeHandler, actionHandler }) => {

    // ----- State
    let [regexAmount, setRegexAmount] = useState(null);


    // ----- Context/Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            amount: '',
            itemsValue: '',
            discount: '',
            extraCharges: '',
        }
    });

    useEffect(() => {
        reset(detail)

        if (detail.measureUnit === "UNITS") {
            setRegexAmount(/^(0|[1-9]\d*)$/)
        }
        else {
            setRegexAmount(/^[^0|\D]\d{0,9}(\.\d{1,2})?$/);
        }

    }, [detail, reset]);


    // ----- Actions
    const handleClose = () => {
        closeHandler();
    }

    const handleAction = (data) => {
        detail.amount = Number(data.amount);
        detail.itemsValue = Number(data.itemsValue);
        detail.discount = Number(data.discount);
        detail.extraCharges = Number(data.extraCharges);
        
        actionHandler(detail);
    }

    // ----- Render
    return (
        <Modal show={show} backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                Editar detalle
            </Modal.Header>
            <Modal.Body>
                <FormStyled onSubmit={handleSubmit(handleAction)}>
                    <p className='fw-bold mb-0 text-center'>{detail.articleName} ({detail.brand})</p>
                    <hr />

                    <label className="d-flex mb-1" htmlFor="amount">Cantidad</label>
                    <input className="form-control" type="text" id="amount" autoComplete="off" maxLength={10} {...register("amount", { required: true, maxLength: 10, pattern: regexAmount })} />
                    <span className="my-1" role="alert">
                        {errors.amount?.type === "required" && `Campo requerido`}
                        {errors.amount?.type === "maxLength" && `Formato inválido`}
                        {errors.amount?.type === "pattern" && detail.measureUnit !== "UNITS" && `Sólo se permiten números (5 - 3.50)`}
                        {errors.amount?.type === "pattern" && detail.measureUnit === "UNITS" && `Sólo se permiten números enteros (5 - 100)`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="itemsValue">Valor por unidad</label>
                    <input className="form-control" type="text" id="itemsValue" autoComplete="off" maxLength={10} {...register("itemsValue", { required: true, maxLength: 10, pattern: /^[^0|\D]\d{0,9}(\.\d{1,2})?$/ })} />
                    <span className="my-1" role="alert">
                        {errors.itemsValue?.type === "required" && `Campo requerido`}
                        {errors.itemsValue?.type === "maxLength" && `Formato inválido`}
                        {errors.itemsValue?.type === "pattern" && `Sólo se permiten números (5 - 3.50)`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="discount">Descuento</label>
                    <input className="form-control" type="text" id="discount" autoComplete="off" maxLength={10} {...register("discount", { maxLength: 10, pattern: /^[^0|\D]\d{0,9}(\.\d{1,2})?$/ })} />
                    <span className="my-1" role="alert">
                        {errors.discount?.type === "maxLength" && `Formato inválido`}
                        {errors.discount?.type === "pattern" && `Sólo se permiten números (5 - 3.50)`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="extraCharges">Otros cargos</label>
                    <input className="form-control" type="text" id="extraCharges" autoComplete="off" maxLength={10} {...register("extraCharges", { maxLength: 10, pattern: /^[^0|\D]\d{0,9}(\.\d{1,2})?$/ })} />
                    <span className="my-1" role="alert">
                        {errors.extraCharges?.type === "maxLength" && `Formato inválido`}
                        {errors.extraCharges?.type === "pattern" && `Sólo se permiten números (5 - 3.50)`}
                    </span>

                    <div className='mt-3 d-flex justify-content-end'>
                        <ModalButton className="cancel" onClick={handleClose}>
                            Cancelar
                        </ModalButton>
                        <ModalButton className="action" type="submit">
                            Editar
                        </ModalButton>
                    </div>
                </FormStyled>
            </Modal.Body>
        </Modal>
    );
}

const FormStyled = styled.form`
    display: grid;

    > label {
        font-size: 13px;
        font-weight: bold;
    }

    > span {
        color: #D04437;
        font-size: 12px;
        min-height: 12px;
    }

    > button {
        background-color: #D04437;
    }
`;

const ModalButton = styled(Button)`
    border: none;

    &.cancel {
        background-color: #A5A7A0;
        margin-right: 15px;
    }
    
    &.action {
        background-color: #D04437;
    }
`;

export default ReceiptEditDetailModal;
