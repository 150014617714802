import { useState } from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import 'moment/locale/es';
import moment from 'moment';

const CustomDatePicker = ({ actionHandler, minDate, maxDate, defaultDate }) => {

    const [datePickerOpen, setDatePickerOpen] = useState(false);


    const handleChange = (date) => {
        if (date) {
            let formattedDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
            actionHandler(formattedDate);
        }
    }

    const handleOpen = () => {
        if (!datePickerOpen) {
            setDatePickerOpen(true);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
            <DatePicker
                minDate={minDate ? minDate : null}
                maxDate={maxDate ? maxDate : null}
                onChange={handleChange}
                defaultValue={defaultDate ? defaultDate : null}
                format={"DD-MM-YYYY"}
                slotProps={{
                    openPickerButton: {
                        onClick: () => handleOpen()
                    },
                    textField: {
                        inputProps: { readOnly: true, placeholder: "día/mes/año" },
                        onClick: () => handleOpen()
                    }
                }}

                sx={{
                    input: {
                        cursor: 'pointer',
                        padding: '10px'
                    }
                }}
                open={datePickerOpen}
                onClose={() => setDatePickerOpen(false)}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;