import { useState } from "react"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";

import { createTag, deleteTag, getTags } from "../services/TagsService"
import { useAppContext } from "../contextApi/context"
import { TAG_NAME_DUPLICATED } from "../constants/apiMessages"
import DeleteConfirmationModal from "../components/shared/DeleteConfirmationModal"
import { TableActionButton } from "../styles/StyledComponents"
import { checkPermission } from "../utils/permissions";
import TableContainer from "../components/shared/TableContainer";

const Tags = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    const [idToDelete, setIdToDelete] = useState(null)
    const [showTagCreated, setShowTagCreated] = useState(false)
    const [showTagDeleted, setShowTagDeleted] = useState(false)
    const [showErrorDuplicatedTag, setShowErrorDuplicatedTag] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            {checkPermission(loggedUser.permissions, 'edit_tags') &&
                <TableActionButton className="btn btn-primary">
                    <Link to={`/etiquetas/${row.id}`}>
                        <MdModeEdit /> Editar
                    </Link>
                </TableActionButton>
            }
            {checkPermission(loggedUser.permissions, 'delete_tags') &&
                <TableActionButton className="btn btn-danger" onClick={() => openDeleteConfirmation(row.id)}>
                    <MdDelete />
                    Eliminar
                </TableActionButton>
            }
        </div>
    );

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Acciones',
            width: "250px",
            cell: row => <Actions row={row} />,
        },
    ];

    const filters = [
        {
            column: 'name',
            input: 'text',
            label: 'Nombre',
            operation: 'like',
            length: 50
        }
    ];


    // ----- Actions
    const handleCloseModal = () => {
        setIdToDelete(null);
        setShowDeleteConfirmation(false);
    }

    const openDeleteConfirmation = (tagId) => {
        setIdToDelete(tagId);
        setShowDeleteConfirmation(true)
    }

    const handleCreate = async (data) => {
        clearMessages();

        const response = await createTag(loggedUser.token, data);

        if (response.status === 201) {
            setShowTagCreated(true)
            reset();
            setUpdateTable(!updateTable);
        } else {
            // Error from API
            const { data } = response;

            if (data.includes(TAG_NAME_DUPLICATED)) {
                setShowErrorDuplicatedTag(true);
            }
        }
    }

    const handleDelete = async () => {
        clearMessages();
        const response = await deleteTag(loggedUser.token, idToDelete);

        if (response.status === 204) {
            setShowDeleteConfirmation(false);
            setUpdateTable(!updateTable);
            setShowTagDeleted(true);
        } else {
            // Error from API
        }
    }

    const clearMessages = () => {
        setShowTagCreated(false);
        setShowErrorDuplicatedTag(false);
        setShowTagDeleted(false);
    }

    // ----- Render
    return (
        <div className="container mt-5">
            {showTagCreated &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Etiqueta creada satisfactoriamente.
                </div>
            }

            {showTagDeleted &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Etiqueta eliminada satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedTag &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    El nombre de la etiqueta ya se encuentra registrado.
                </div>
            }

            <h1>Etiquetas</h1>

            {checkPermission(loggedUser.permissions, 'add_tags') &&
                <FormContainer>
                    <form onSubmit={handleSubmit(handleCreate)}>
                        <h3 className="mb-4">Crear etiqueta nueva</h3>
                        <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                        <input className="form-control" type="text" id="name" autoComplete="off" maxLength={50} {...register("name", { required: true, maxLength: 50 })} autoFocus />
                        <span className="my-1" role="alert">
                            {errors.name?.type === "required" && `Campo requerido`}
                            {errors.name?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <button className="btn btn-danger mt-3" type="submit">Guardar</button>
                    </form>
                </FormContainer>
            }

            <TableContainer
                columns={columns}
                fetchDataHandler={getTags}
                updateTable={updateTable}
                filters={filters}
            />

            {showDeleteConfirmation &&
                <DeleteConfirmationModal
                    text={"Desea eliminar el etiqueta? Al confirmar esta acción, la etiqueta será removida de cualquier asociación existente."}
                    closeHandler={handleCloseModal}
                    actionHandler={handleDelete}
                    show={showDeleteConfirmation}
                />
            }
        </div>
    )
}

const FormContainer = styled.div`
    border: #dee2e6 solid 1px;
    margin: 25px auto;
    padding: 40px;
    width: 60%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        > button {
            background-color: #D04437;
            margin: 0 auto;
            width: 30%;
        }

        > textarea {
            resize: none;
        }
    }
`;

export default Tags;