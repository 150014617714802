import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import { useAppContext } from './contextApi/context';

import RoutesList from './router/RoutesList';
import Header from "./components/shared/Header";
import Sidebar from "./components/shared/Sidebar";

import TimeoutModal from './components/TimeoutModal';
import { checkSessionTime } from './utils/session';
import { renewSession } from './services/AuthService';

const App = () => {
  // ----- Context/Hooks
  const { loggedUser, loggedInTime, setLoggedUser, setLoggedInTime, setLastActionTime } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  let isModalOpened = false;

  // ----- State
  let [timer, setTimer] = useState(null);
  let [showTimeoutModal, setShowTimeoutModal] = useState(false);


  const activityListener = () => {
    if (!isModalOpened) {
      let newActivityTime = Date.now();
      localStorage.setItem("lastActionTime", newActivityTime);
      setLastActionTime(newActivityTime);
    }
  };

  // ----- Actions
  const handleTimer = () => {
    clearInterval(timer);

    if (loggedUser != null) {
      killAllIntervals();

      setTimer(setInterval(() => {
        let showModal = checkSessionTime(loggedInTime);

        if (showModal && !isModalOpened) {
          let storedLastActionTime = localStorage.getItem("lastActionTime");
          let activityDiffInMinutes = (Date.now() / 60000) - (storedLastActionTime / 60000);

          if (activityDiffInMinutes >= 27) {
            setShowTimeoutModal(true);
            isModalOpened = true;
            clearInterval(timer);
          } else {
            handleContinueSession();
          }
        }
      }, 1000));
    }
  }

  const handleContinueSession = async () => {
    killAllIntervals();

    const response = await renewSession(loggedUser.token);
    if (response.token) {
      localStorage.setItem("loggedUser", JSON.stringify(response));
      setLoggedUser(response);

      let newLoggedInTime = Date.now();
      localStorage.setItem("loggedInTime", newLoggedInTime);
      setLoggedInTime(newLoggedInTime);

      setShowTimeoutModal(false);
      isModalOpened = false;
      handleTimer();
    }
  }

  const handleKillSession = () => {
    setShowTimeoutModal(false);
    handleTimer();

    localStorage.removeItem("loggedUser");
    setLoggedUser(null);
    localStorage.removeItem("loggedInTime");
    setLoggedInTime(null);
    localStorage.removeItem("lastActionTime");
    setLastActionTime(null);

    navigate("/login");
  }

  const killAllIntervals = () => {
    const intervalId = setInterval(function () { }, Number.MAX_SAFE_INTEGER);
    for (let i = 0; i < intervalId; i++) {
      clearInterval(i);
    }
  }

  const avoidDoubleClick = () => {
    let lastClickElement = null;
    let lastClickTime = Date.now();

    document.addEventListener("click", function (e) {
      const { target } = e;
      const now = Date.now();
      if (target === lastClickElement && (now - lastClickTime) < 1000) {
        // Same element and less than one second
        e.preventDefault();
        e.stopPropagation();
      }
      lastClickElement = target;
      lastClickTime = now;
    }, true);
  }

  useEffect(() => {
    // console.log('HOOOLAAAA loggedUser in APP.js: ', loggedUser);
    handleTimer();

    if (location.pathname === '/recuperar-contrasena') {
      navigate("/recuperar-contrasena")
    } else if (location.pathname === '/cambiar-contrasena') {
      navigate("/cambiar-contrasena")
    }
    else if (loggedUser == null) {
      navigate("/login")
    }

    if (loggedUser) {
      window.addEventListener('keydown', activityListener);
      window.addEventListener('click', activityListener);
    }

    avoidDoubleClick();

    return () => {
      window.removeEventListener('keydown', activityListener);
      window.removeEventListener('click', activityListener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, loggedInTime, location.pathname, navigate]);

  // ----- Render
  return (
    <WrapperStyled>
      <Header />
      <MainStyled>
        {loggedUser && <Sidebar />}
        <ContentStyled>
          <RoutesList />
        </ContentStyled>
      </MainStyled>
      {showTimeoutModal &&
        <TimeoutModal
          logoutHandler={handleKillSession}
          continueHandler={handleContinueSession}
          show={showTimeoutModal}
        />
      }
    </WrapperStyled>
  );
}

// ----- Styles
const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

const MainStyled = styled.div`
    display: flex;
    flex-grow: 1;
`;

const ContentStyled = styled.div`
    flex-grow: 1;
`;

export default App;
