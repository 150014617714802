import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getProviders = async (token, page, size, sortField, filtersData) => {
    
    const url = sortField ? `${BASE_API_URL}/providers/search?page=${page}&size=${size}&sort=${sortField}`
        : `${BASE_API_URL}/providers/search?page=${page}&size=${size}`;

    return axios.post(url, filtersData, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getProviders call: ', error);
        return error;
    });
}

export const getProvider = async (token, id) => {
    const url = `${BASE_API_URL}/providers/${id}`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    ).then(response => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getProvider call: ', error);
        return error;
    })
}

export const createProvider = async (token, data) => {
    const url = `${BASE_API_URL}/providers`;
    const payload = {
        name: data.name,
        email: data.email,
        address: data.address,
        phoneNumber: data.phoneNumber,
        contactName: data.contactName,
        code: data.code,
        identification: data.identification,
        whatsapp: data.whatsapp,
        contactEmail: data.contactEmail,
        contactPhone: data.contactPhone,
        description: data.description
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on createProvider call: ', error);
        return error.response;
    });
}

export const editProvider = async (token, providerId, data) => {
    const url = `${BASE_API_URL}/providers/${providerId}`;
    const payload = {
        name: data.name,
        email: data.email,
        address: data.address,
        phoneNumber: data.phoneNumber,
        contactName: data.contactName,
        code: data.code,
        identification: data.identification,
        whatsapp: data.whatsapp,
        contactEmail: data.contactEmail,
        contactPhone: data.contactPhone,
        description: data.description,
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editProvider call: ', error);
        return error.response;
    });
}

export const deleteProvider = async (token, userId) => {
    const url = `${BASE_API_URL}/providers/${userId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteProvider call: ', error);
        return error.response;
    });
}

export const addTag = async (token, providerId, tagName) => {
    const url = `${BASE_API_URL}/providers/${providerId}/tags`;
    const payload = {
        name: tagName
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on addTag call: ', error);
        return error.response;
    });
}

export const removeTag = async (token, providerId, tagId) => {
    const url = `${BASE_API_URL}/providers/${providerId}/tags/${tagId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on removeTag call: ', error);
        return error.response;
    });
}

export const searchProvidersByName = async (token, text) => {
    const url = `${BASE_API_URL}/providers/list`;

    return axios.post(url, text, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            const { data } = response;
            return data;
        }).catch(error => {
            console.log('Error on searchTagsByName call: ', error);
            return error;
        });
}