import { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

import { createArticle, deleteArticle, getArticles, getUnits } from "../services/ArticlesService"
import { useAppContext } from "../contextApi/context"
import { ARTICLE_ASSOCIATED_WITH_STOCK, ARTICLE_CODE_DUPLICATED } from "../constants/apiMessages"
import { measureUnit } from "../utils/translation";
import { checkPermission } from "../utils/permissions";
import DeleteConfirmationModal from "../components/shared/DeleteConfirmationModal"
import ConfirmationModal from "../components/shared/ConfirmationModal"
import { AddButton, TableActionButton } from "../styles/StyledComponents";
import TableContainer from "../components/shared/TableContainer";


const Articles = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    let [measureUnits, setMeasureUnits] = useState([])
    const [idToDelete, setIdToDelete] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [showArticleDeleted, setShowArticleDeleted] = useState(false)
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false)
    const [showErrorAssignedArticle, setShowErrorAssignedArticle] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('')
    const [updateTable, setUpdateTable] = useState(false);

    // ----- API Fetch Actions
    const fetchUnitsData = useCallback(async () => {
        return await getUnits(loggedUser.token);
    }, [loggedUser.token]);

    // ----- Hooks
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        fetchUnitsData().then(data => {
            setMeasureUnits(data);
        })

    }, [fetchUnitsData]);


    // ----- Actions
    const handleCloseDeleteConfirmationModal = () => {
        setIdToDelete(null);
        setShowDeleteConfirmation(false);
    }

    const openDeleteConfirmation = (articleId) => {
        setIdToDelete(articleId);
        setShowDeleteConfirmation(true)
    }

    const handleCreate = async (data) => {
        clearMessages();

        const response = await createArticle(loggedUser.token, data);

        if (response.status === 201) {
            navigate(`/articulos/${response.data.id}`);
        } else {
            // Error from API
            const { data } = response;
            let errorMessageDuplicated = "El artículo contiene datos que ya se encuentran registrados:";

            if (data.includes(ARTICLE_CODE_DUPLICATED)) {
                errorMessageDuplicated += " Código,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);

        }
    }

    const handleShowForm = () => {
        setShowForm(!showForm);
    }

    const handleDelete = async () => {
        clearMessages();
        const response = await deleteArticle(loggedUser.token, idToDelete);

        if (response.status === 204) {
            setShowDeleteConfirmation(false);
            setUpdateTable(!updateTable);
            setShowArticleDeleted(true);
        } else {
            // Error from API
            const { data } = response;

            if (data === ARTICLE_ASSOCIATED_WITH_STOCK) {
                setShowDeleteConfirmation(false);
                setShowErrorAssignedArticle(true);
            }
        }
    }

    const handleShowConfirmationModal = () => {
        setShowConfirmationModal(true);
    }

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const handleResetForm = () => {
        reset();
        setShowForm(false);
        setShowConfirmationModal(false);
    }

    const clearMessages = () => {
        setShowErrorDuplicatedData(false);
        setShowArticleDeleted(false);
        setShowErrorAssignedArticle(false);
        setErrorDuplicatedData('');
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            {checkPermission(loggedUser.permissions, 'edit_articles') &&
                <TableActionButton className="btn btn-primary">
                    <Link to={`/articulos/${row.id}`}>
                        <MdModeEdit /> Editar
                    </Link>
                </TableActionButton>
            }
            {checkPermission(loggedUser.permissions, 'delete_articles') &&
                <TableActionButton className="btn btn-danger" onClick={() => openDeleteConfirmation(row.id)}>
                    <MdDelete />
                    Eliminar
                </TableActionButton>
            }
        </div>
    );

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Código',
            selector: row => row.internalCode,
            sortable: true,
            sortField: 'internalCode',
        },
        {
            name: 'Cantidad mínima',
            selector: row => row.minimumAmount,
        },
        {
            name: 'Acciones',
            width: "215px",
            cell: row => <Actions row={row} />,
        },
    ];

    const filters = [
        {
            column: 'name',
            input: 'text',
            label: 'Nombre',
            operation: 'like',
            length: 50
        },
        {
            column: 'internalCode',
            input: 'text',
            label: 'Código',
            operation: 'like',
            length: 10
        },
        {
            column: 'tag',
            input: 'tags',
            label: 'Etiquetas',
            operation: 'in',
        },
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            {showArticleDeleted &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Artículo eliminado satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            {showErrorAssignedArticle &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    El artículo no se puede eliminar porque ya es parte del inventario.
                </div>
            }

            <h1>Artículos</h1>

            <div className="d-flex mt-3 justify-content-end">
                {checkPermission(loggedUser.permissions, 'add_articles') &&
                    <AddButton className="btn btn-primary" onClick={handleShowForm}>
                        <FaPlus /> Agregar nuevo
                    </AddButton>
                }
            </div>

            {showForm &&
                <FormContainer>
                    <form onSubmit={handleSubmit(handleCreate)}>
                        <label className="d-flex mb-1" htmlFor="name">Nombre</label>
                        <input className="form-control" type="text" id="name" autoComplete="off" maxLength={50} {...register("name", { required: true, maxLength: 50 })} autoFocus />
                        <span className="my-1" role="alert">
                            {errors.name?.type === "required" && `Campo requerido`}
                            {errors.name?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="internalCode">Código</label>
                        <input className="form-control" type="text" id="internalCode" autoComplete="off" maxLength={25} {...register("internalCode", { required: true, maxLength: 25 })} />
                        <span className="my-1" role="alert">
                            {errors.internalCode?.type === "required" && `Campo requerido`}
                            {errors.internalCode?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="minimumAmount">Cantidad mínima</label>
                        <input className="form-control" type="text" id="minimumAmount" autoComplete="off" maxLength={10} {...register("minimumAmount", { required: true, pattern: /^\d*\.?\d*$/, maxLength: 10 })} />
                        <span className="my-1" role="alert">
                            {errors.minimumAmount?.type === "required" && `Campo requerido`}
                            {errors.minimumAmount?.type === "maxLength" && `Formato inválido`}
                            {errors.minimumAmount?.type === "pattern" && `Sólo se permiten números (5 - 3.5)`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="measureUnit">Unidad de medida</label>
                        <select className="form-select" {...register("measureUnit", { required: true })}>
                            <option value="">Seleccione una medida</option>
                            {measureUnits?.map((unit, index) =>
                                (<option key={index} value={unit}>{measureUnit(unit)}</option>)
                            )}
                        </select>
                        <span className="my-1" role="alert">
                            {errors.measureUnit && `Campo requerido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="description">Descripción</label>
                        <textarea className="form-control" id="description" maxLength={100} rows="3" {...register("description", { maxLength: 100 })} />
                        <span className="my-1" role="alert">
                            {errors.description?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <div className="d-flex justify-content-center mt-3">
                            <button className="btn btn-primary reset" type="button" onClick={handleShowConfirmationModal}>Cancelar</button>
                            <button className="btn btn-danger" type="submit">Guardar</button>
                        </div>
                    </form>
                </FormContainer>
            }

            <TableContainer
                columns={columns}
                fetchDataHandler={getArticles}
                updateTable={updateTable}
                filters={filters}
            />

            {showDeleteConfirmation &&
                <DeleteConfirmationModal
                    text={"Desea eliminar el artículo?"}
                    closeHandler={handleCloseDeleteConfirmationModal}
                    actionHandler={handleDelete}
                    show={showDeleteConfirmation}
                />
            }

            {showConfirmationModal &&
                <ConfirmationModal
                    closeHandler={handleCloseConfirmationModal}
                    actionHandler={handleResetForm}
                    show={showConfirmationModal}
                />
            }
        </div>
    )
}


const FormContainer = styled.div`
    border: #dee2e6 solid 1px;
    margin: 25px auto;
    padding: 40px;
    width: 60%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        div > button {
            &.reset {
                background-color: #A5A7A0;
                border-color: #A5A7A0;
                margin-right: 20px;

                &:active {
                    background-color: #A5A7A0;
                    border-color: #A5A7A0;
                }
            }
        }

        > textarea {
            resize: none;
        }
    }
`;

export default Articles;