import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "styled-components"

import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

import { createUser, deleteUser, getUsers } from "../services/UsersService"
import { listRoles } from "../services/RolesService"
import { useAppContext } from "../contextApi/context"
import { USER_USERNAME_DUPLICATED, USER_EMAIL_DUPLICATED } from "../constants/apiMessages"
import DeleteConfirmationModal from "../components/shared/DeleteConfirmationModal"
import ConfirmationModal from "../components/shared/ConfirmationModal"
import { AddButton, TableActionButton } from "../styles/StyledComponents"
import { checkPermission } from "../utils/permissions";
import TableContainer from "../components/shared/TableContainer";

const Users = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    const [idToDelete, setIdToDelete] = useState(null)
    const [roles, setRoles] = useState([])
    const [showForm, setShowForm] = useState(false)
    const [showUserCreated, setShowUserCreated] = useState(false)
    const [showUserDeleted, setShowUserDeleted] = useState(false)
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('')
    const [updateTable, setUpdateTable] = useState(false);

    // ----- API Fetch Actions
    const fetchRolesData = useCallback(async () => {
        return await listRoles(loggedUser.token);
    }, [loggedUser.token]);

    // ----- Hooks
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
        fetchRolesData().then(data => {
            setRoles(data);
        })

    }, [fetchRolesData]);


    // ----- Actions
    const handleCreate = async (data) => {
        clearMessages();
        const response = await createUser(loggedUser.token, data);

        if (response.status === 201) {
            setShowUserCreated(true);
            reset();
            setShowForm(false);
            setUpdateTable(!updateTable);
        } else {
            // Error from API
            const { data } = response;
            let errorMessageDuplicated = "El usuario contiene datos que ya se encuentran registrados:";

            if (data.includes(USER_USERNAME_DUPLICATED)) {
                errorMessageDuplicated += " Nombre de usuario,"
            }

            if (data.includes(USER_EMAIL_DUPLICATED)) {
                errorMessageDuplicated += " Correo electrónico,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);
        }
    }

    const handleShowForm = () => {
        setShowForm(!showForm);
    }

    const handleCloseDeleteConfirmationModal = () => {
        setIdToDelete(null);
        setShowDeleteConfirmation(false);
    }

    const openDeleteConfirmation = (userId) => {
        setIdToDelete(userId);
        setShowDeleteConfirmation(true)
    }

    const handleDelete = async () => {
        clearMessages();

        const response = await deleteUser(loggedUser.token, idToDelete);

        if (response.status === 204) {
            setShowDeleteConfirmation(false);
            setUpdateTable(!updateTable);
            setShowUserDeleted(true);
        } else {
            // Error from API
        }
    }

    const handleShowConfirmationModal = () => {
        setShowConfirmationModal(true);
    }

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const handleResetForm = () => {
        reset();
        setShowForm(false);
        setShowConfirmationModal(false);
    }

    const clearMessages = () => {
        setShowUserCreated(false);
        setShowErrorDuplicatedData(false);
        setShowUserDeleted(false);
        setErrorDuplicatedData('');
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            {checkPermission(loggedUser.permissions, 'edit_users') &&
                <TableActionButton className="btn btn-primary">
                    <Link to={`/usuarios/${row.id}`}>
                        <MdModeEdit /> Editar
                    </Link>
                </TableActionButton>
            }
            {checkPermission(loggedUser.permissions, 'delete_users') &&
                <TableActionButton className="btn btn-danger" onClick={() => openDeleteConfirmation(row.id)}>
                    <MdDelete />
                    Eliminar
                </TableActionButton>
            }
        </div>
    );

    const columns = [
        {
            name: 'Usuario',
            selector: row => row.username,
            sortable: true,
            sortField: 'username',
        },
        {
            name: 'Nombre',
            selector: row => row.firstName,
            sortable: true,
            sortField: 'firstName',
        },
        {
            name: 'Apellido',
            selector: row => row.lastName,
            sortable: true,
            sortField: 'lastName',
        },
        {
            name: 'Correo electrónico',
            selector: row => row.email,
        },
        {
            name: 'Rol',
            selector: row => row.roleName,
        },
        {
            name: 'Acciones',
            width: "210px",
            cell: row => <Actions row={row} />,
        },
    ];

    const filters = [
        {
            column: 'username',
            input: 'text',
            label: 'Usuario',
            operation: 'like',
            length: 50
        },
        {
            column: 'email',
            input: 'text',
            label: 'Correo electrónico',
            operation: 'like',
            length: 50
        },
        {
            column: 'roleName',
            input: 'text',
            label: 'Rol',
            operation: 'like',
            length: 50
        },
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            {showUserCreated &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Usuario creado satisfactoriamente.
                </div>
            }

            {showUserDeleted &&
                <div className="alert alert-success text-center mb-1" role="alert">
                    Usuario eliminado satisfactoriamente.
                </div>
            }

            {showErrorDuplicatedData &&
                <div className="alert alert-danger text-center mb-1" role="alert">
                    {errorDuplicatedData}
                </div>
            }

            <h1>Usuarios</h1>

            <div className="d-flex mt-3 justify-content-end">
                {checkPermission(loggedUser.permissions, 'add_users') &&
                    <AddButton className="btn btn-primary" onClick={handleShowForm}>
                        <FaPlus /> Agregar nuevo
                    </AddButton>
                }
            </div>

            {showForm &&
                <FormContainer>
                    <form onSubmit={handleSubmit(handleCreate)}>
                        <label className="d-flex mb-1" htmlFor="firstName">Nombre</label>
                        <input className="form-control" type="text" id="firstName" autoComplete="off" {...register("firstName", { required: true, maxLength: 50 })} maxLength={50} autoFocus />
                        <span className="my-1" role="alert">
                            {errors.firstName?.type === "required" && `Campo requerido`}
                            {errors.firstName?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="lastName">Apellido</label>
                        <input className="form-control" type="text" id="lastName" autoComplete="off" {...register("lastName", { required: true, maxLength: 50 })} maxLength={50} />
                        <span className="my-1" role="alert">
                            {errors.lastName?.type === "required" && `Campo requerido`}
                            {errors.lastName?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="email">Correo electrónico</label>
                        <input className="form-control" type="text" id="email" autoComplete="off" {...register("email", { required: true, maxLength: 50, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })} maxLength={50} />
                        <span className="my-1" role="alert">
                            {errors.email?.type === "required" && `Campo requerido`}
                            {errors.email?.type === "maxLength" && `Formato inválido`}
                            {errors.email?.type === "pattern" && `Formato de correo incorrecto`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="username">Nombre de usuario</label>
                        <input className="form-control" type="text" id="username" autoComplete="off" {...register("username", { required: true, maxLength: 50 })} maxLength={50} />
                        <span className="my-1" role="alert">
                            {errors.username?.type === "required" && `Campo requerido`}
                            {errors.username?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="email">Contraseña</label>
                        <input className="form-control" type="password" id="password" autoComplete="off" {...register("password", { required: true, maxLength: 50 })} maxLength={50} />
                        <span className="my-1" role="alert">
                            {errors.password?.type === "required" && `Campo requerido`}
                            {errors.password?.type === "maxLength" && `Formato inválido`}
                        </span>

                        <label className="d-flex mb-1" htmlFor="role">Rol</label>
                        <select className="form-select" id="role" {...register("role", { required: true })}>
                            <option value="">Seleccione un rol</option>
                            {roles?.map(role =>
                                (<option key={role.id} value={role.id}>{role.name}</option>)
                            )}
                        </select>
                        <span className="my-1" role="alert">
                            {errors.role && `Campo requerido`}
                        </span>

                        <div className="d-flex justify-content-center mt-3">
                            <button className="btn btn-primary reset" type="button" onClick={handleShowConfirmationModal}>Cancelar</button>
                            <button className="btn btn-danger" type="submit">Guardar</button>
                        </div>

                    </form>
                </FormContainer>
            }

            <TableContainer
                columns={columns}
                fetchDataHandler={getUsers}
                filters={filters}
                updateTable={updateTable}
            />

            {showDeleteConfirmation &&
                <DeleteConfirmationModal
                    text={"Desea eliminar el usuario?"}
                    closeHandler={handleCloseDeleteConfirmationModal}
                    actionHandler={handleDelete}
                    show={showDeleteConfirmation}
                />
            }

            {showConfirmationModal &&
                <ConfirmationModal
                    closeHandler={handleCloseConfirmationModal}
                    actionHandler={handleResetForm}
                    show={showConfirmationModal}
                />
            }
        </div>
    )
}


const FormContainer = styled.div`
    border: #dee2e6 solid 1px;
    margin: 25px auto;
    padding: 40px;
    width: 85%;

    form {
        display: grid;
    
        > label {
            font-size: 15px;
            font-weight: bold;
        }

        > span {
            color: #D04437;
            font-size: 13px;
            min-height: 12px;
        }

        div > button {
            &.reset {
                background-color: #A5A7A0;
                border-color: #A5A7A0;
                margin-right: 20px;
                
                &:active {
                    background-color: #A5A7A0;
                    border-color: #A5A7A0;
                }
            }
        }
    }
`;

export default Users;