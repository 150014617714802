import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { styled } from "styled-components";
import { useForm } from "react-hook-form"

import { useAppContext } from '../../contextApi/context';
import { assignArticleProvider } from '../../services/ArticleProviderService';
import { PROVIDER_ARTICLE_BRAND_DUPLICATED, PROVIDER_ARTICLE_INTERNAL_CODE_DUPLICATED } from "../../constants/apiMessages";

const AssignArticleModal = ({ show, article, provider, closeHandler, actionHandler }) => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const { register, handleSubmit, formState: { errors } } = useForm();

    // ----- State
    const [errorDuplicatedData, setErrorDuplicatedData] = useState('');
    const [showErrorDuplicatedData, setShowErrorDuplicatedData] = useState(false);

    // ----- Actions
    const handleClose = () => {
        closeHandler();
    }

    const handleAction = async (data) => {
        setShowErrorDuplicatedData(false);
        
        const payload = {
            providerId: provider.id,
            articleId: article.id,
            internalProviderCode: data.internalProviderCode,
            brand: data.brand,
        };

        let response = await assignArticleProvider(loggedUser.token, payload);

        if (response.status === 201) {
            actionHandler(response.data);
        }
        else {
            const { data } = response;
            let errorMessageDuplicated = "La asociación no se puede crear porque hay datos ya registrados:";

            if (data.includes(PROVIDER_ARTICLE_INTERNAL_CODE_DUPLICATED)) {
                errorMessageDuplicated += " Código interno,"
            }

            if (data.includes(PROVIDER_ARTICLE_BRAND_DUPLICATED)) {
                errorMessageDuplicated += " Marca,"
            }

            setErrorDuplicatedData(errorMessageDuplicated.replace(/.$/, "."));
            setShowErrorDuplicatedData(true);
        }

    }

    // ----- Render
    return (
        <Modal show={show} backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                Asignar artículo a proveedor
            </Modal.Header>
            <Modal.Body>
                
                {showErrorDuplicatedData &&
                    <div className="alert alert-danger text-center mb-3 py-2" role="alert">
                        {errorDuplicatedData}
                    </div>
                }

                <div className="d-flex">
                    <label className="fw-bold me-2">Proveedor:</label>
                    <p className='mb-2'>{provider.name}</p>
                </div>
                <div className="d-flex">
                    <label className="fw-bold me-2">Artículo:</label>
                    <p className='mb-0'>{article.label}</p>
                </div>

                <hr />

                <FormStyled onSubmit={handleSubmit(handleAction)}>
                    <label className="d-flex mb-1" htmlFor="brand">Marca</label>
                    <input className="form-control" type="text" id="brand" maxLength={25} {...register("brand", { required: true, maxLength: 25 })} autoFocus autoComplete='off' />
                    <span className="my-1" role="alert">
                        {errors.brand?.type === "required" && `Campo requerido`}
                        {errors.brand?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="internalProviderCode">Código interno</label>
                    <input className="form-control" type="text" id="internalProviderCode" maxLength={25} {...register("internalProviderCode", { required: true, maxLength: 25 })} autoComplete='off' />
                    <span className="my-1" role="alert">
                        {errors.internalProviderCode?.type === "required" && `Campo requerido`}
                        {errors.internalProviderCode?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <button className="btn btn-danger mt-3" type="submit">Asignar</button>
                </FormStyled>
            </Modal.Body>
        </Modal>
    );
}

const FormStyled = styled.form`
    display: grid;

    > label {
        font-size: 13px;
        font-weight: bold;
    }

    > span {
        color: #D04437;
        font-size: 12px;
        min-height: 12px;
    }

    > button {
        background-color: #D04437;
    }
`;

export default AssignArticleModal;
