import { permissionsHierarchy } from "../constants/permissionsHierarchy";

export const checkPermission = (userPermissions, code) => {
    return userPermissions?.find(item => item.code === code);
}

export const redirectAfterLogin = (userPermissions) => {
    for (let i = 0; i < permissionsHierarchy.length; i++) {
        const permission = permissionsHierarchy[i];
        let found = userPermissions.find(userPermission => userPermission.code === permission.code)
        if (found) {
            return permission.to;
        }
    }
    
    return '';
}

export const checkReportPermissions = (userPermissions) => {
    const reportPermissions = ['view_financial_report', 'view_movements_report', 'view_minimums_report', 'view_stock_report']
    return userPermissions.some(permission => reportPermissions.includes(permission.code));
}