import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const assignArticleProvider = async (token, data) => {
    const url = `${BASE_API_URL}/articleProviders`;
    const payload = {
        articleId: data.articleId,
        providerId: data.providerId,
        internalProviderCode: data.internalProviderCode,
        brand: data.brand
    };

    return axios.post(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on assignArticleProvider call: ', error);
        return error.response;
    });
}

export const deleteAssignment = async (token, assignmentId) => {
    const url = `${BASE_API_URL}/articleProviders/${assignmentId}`;

    return axios.delete(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on deleteAssignment call: ', error);
        return error.response;
    });
}

export const editAssignment = async (token, data) => {
    const url = `${BASE_API_URL}/articleProviders/${data.id}`;
    const payload = {
        articleId: data.articleId,
        providerId: data.providerId,
        internalProviderCode: data.internalProviderCode,
        brand: data.brand
    };

    return axios.put(url, payload, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        return response;
    }).catch(error => {
        console.log('Error on editAssignment call: ', error);
        return error.response;
    });
}
