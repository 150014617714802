import { useEffect } from "react";
import { useNavigate } from "react-router-dom"

import { useAppContext } from "../contextApi/context";
import { redirectAfterLogin } from "../utils/permissions"

const Dashboard = () => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedUser == null) {
            navigate("/login")
        } else {
            navigate(redirectAfterLogin(loggedUser.permissions));
        }
    }, [loggedUser, navigate]);

    // ----- Render
    return (
        <div className="container mt-5">
            {/* <h1 className="text-center">Página Principal</h1>
            <h3 className="mt-5">Bienvenido, {loggedUser?.firstName} {loggedUser?.lastName}!</h3>
            <div className="mt-5 text-center">
                <img className="mt-5" alt="Trabajo en progreso" src={inProgressImg} />
            </div> */}
        </div>
    )
}

export default Dashboard;