import axios from 'axios';
import { BASE_API_URL } from '../constants/env';

export const getPermissions = async (token) => {

    const url = `${BASE_API_URL}/permissions`;

    return axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then((response) => {
        const { data } = response;
        return data;
    }).catch(error => {
        console.log('Error on getRoles call: ', error);
        return error;
    });
}